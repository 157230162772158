import { Payment } from './payment.model';
import { iDirectDebit, iDirectDebitIncassoAt } from '@semmie/schemas/bi/payment';

export class DirectDebit extends Payment implements iDirectDebit {
  period: 'month' | 'quarter';
  created_at: Date;
  next_incasso_at: string | boolean | Array<iDirectDebitIncassoAt>;
  user: { id: string };

  constructor(props?: Partial<iDirectDebit>) {
    super(props);
  }

  get nextIncassoAt() {
    if (Array.isArray(this.next_incasso_at)) {
      return this.next_incasso_at[0]?.auto_incasso_at;
    }

    return this.next_incasso_at;
  }
}
