import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { ModalService } from '@semmie/services/modal/modal.service';
import { NavigationService } from '@semmie/services/navigation/navigation.service';

import { AccountStatusModalComponent } from '@semmie/components';
import { AccountStatusModalCallback } from '@semmie/schemas/bi/account';
import { ModalSize } from '@semmie/schemas/components/modal';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { ImpactStyle } from '@capacitor/haptics';
import { MainRouteNames } from '@onyxx/model/main';
import { Router } from '@angular/router';
import { SettingsRouteNames } from '@semmie/views/settings/settings.common';
import { AccountStatus } from '@onyxx/model/account';

@Injectable({ providedIn: 'root' })
export class OpenTasksService {
  constructor(
    private modalService: ModalService,
    private navigationService: NavigationService,
    private hapticFeedbackService: HapticFeedbackService,
    private router: Router,
  ) {}

  /**
   * Opens pending task modal if needed
   */
  checkTasks(): void {
    if (
      !this.router.isActive(`${MainRouteNames.Settings}/${SettingsRouteNames.UserTasks}`, {
        paths: 'exact',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })
    ) {
      this.openPendingTasksModal(['/', MainRouteNames.Settings, SettingsRouteNames.UserTasks]);
    }
  }

  private async openPendingTasksModal(taskPath: string[]): Promise<void> {
    await this.modalService.open(
      AccountStatusModalComponent,
      {
        componentProps: {
          status: AccountStatus.TASK,
        },
      },
      { size: ModalSize.Full },
    );
    this.hapticFeedbackService.interact(ImpactStyle.Heavy);

    this.modalService.onWillClose$.pipe(take(1)).subscribe((closeCallback: AccountStatusModalCallback) => {
      if (closeCallback === AccountStatusModalCallback.TASK) {
        this.navigationService.navigate(taskPath, { animated: true });
      }
    });
  }
}
