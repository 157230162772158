import { Route } from '@angular/router';
import { AppRouteNames } from '@onyxx/model/main';
import { IsAppGuard, CriticalTaskGuard } from '@semmie/guards';
import { InitializerGuard } from '@semmie/guards/authentication/initializer.guard';

export const authenticatedAreaRoutes: Route[] = [
  {
    path: AppRouteNames.PinCode,
    loadComponent: () => import('@semmie/views/pincode/pincode.component'),
    canActivate: [IsAppGuard],
  },
  {
    path: AppRouteNames.Biometrics,
    loadChildren: () => import('@semmie/views/authentication/biometric/biometric.module').then((m) => m.BiometricModule),
    canActivate: [IsAppGuard],
  },
  {
    path: AppRouteNames.Notifications,
    loadComponent: () => import('@semmie/views/authentication/notifications/notifications.component').then((m) => m.NotificationsComponent),
    canActivate: [IsAppGuard],
  },
  {
    path: AppRouteNames.UrgentTasks,
    pathMatch: 'full',
    loadComponent: () => import('@onyxx/feature/user-tasks').then((m) => m.SelectFirstUrgentTaskComponent),
    data: { monitorInactivity: true },
    canActivate: [InitializerGuard],
  },
  {
    path: AppRouteNames.UrgentTasks,
    loadChildren: () => import('@onyxx/feature/user-tasks').then((m) => m.userTaskRoutes),
    data: { monitorInactivity: true },
    canActivate: [InitializerGuard],
  },
  {
    path: '',
    loadChildren: () => import('@semmie/views/main/main.module'),
    runGuardsAndResolvers: 'always',
    canActivate: [InitializerGuard, CriticalTaskGuard],
    data: { monitorInactivity: true },
  },
];
