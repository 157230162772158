<ng-container
  *ngIf="{
    booster: booster$ | async,
    image: image$ | async,
    buttonDisabled: buttonDisabled$ | async,
  } as ctx"
>
  <ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="'carousel'">
      <ng-container *ngTemplateOutlet="carouselLayout; context: { $implicit: ctx.booster }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'item'">
      <ng-container *ngTemplateOutlet="itemLayout; context: { $implicit: ctx.booster }"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #carouselLayout let-booster>
    <onyxx-content-to-string (valueChanges)="rewardText$$.set($event)">
      @if (booster.rewardKind === RewardKind.MONTHS_NO_FEE) {
        <ng-container i18n="@@boosters.rewards.months-no-fee">{booster.amount, plural,
          =0 {0 months no fee}
          =1.0 {+1 month no fee}
          other {+{{ booster.amount | number: '1.0' }} months no fee}
        }</ng-container>
      } @else {
        {{ booster.amount | semmieCurrency: true : 'decimalsIfAvailable' }}
      }
    </onyxx-content-to-string>
    <onyxx-content-to-string (valueChanges)="rewardTextDescription$$.set($event)">
      @if (booster.rewardKind === RewardKind.MONTHS_NO_FEE) {
        <ng-container i18n="@@boosters.rewards.months-no-fee-sentence">{booster.amount, plural,
          =0 {0 months no fee}
          =1.0 {1 month investment without management fee}
          other {{{ booster.amount | number: '1.0' }} months investment without management fee}
        }</ng-container>
      } @else {
        {{ booster.amount | semmieCurrency: true : 'decimalsIfAvailable' }}
      }
    </onyxx-content-to-string>
    @switch (booster.type) {
      @case (BoosterType.AUTO_INCASSO) {
        <semmie-content-card
          i18n-title="@@boosters.types.auto-incasso.title"
          title="Direct debit"
          i18n-description="@@boosters.types.auto-incasso.description"
          description="Easily deposit automatically? Set up a direct debit on one of your accounts and receive {{
            rewardTextDescription$$()
          }}."
          [image]="ctx.image"
          i18n-cta="@@boosters.types.auto-incasso.button"
          cta="Set now"
          [ctaDisabled]="ctx.buttonDisabled ?? false"
          (ctaInteraction)="primaryCta(booster.type)"
        >
          <div header class="text-right">
            <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
          </div>
        </semmie-content-card>
      }
      @case (BoosterType.REFERRAL) {
        <semmie-content-card
          i18n-title="@@boosters.types.referral.title"
          title="Invite a friend"
          i18n-description="@@boosters.types.referral.description"
          description="Invite friends and family to invest with Semmie and both receive {{
            rewardTextDescription$$()
          }}. Start inviting now."
          [image]="ctx.image"
          [cta]="referralLink$$() ?? ''"
          [ctaDisabled]="ctx.buttonDisabled ?? false"
          (ctaInteraction)="primaryCta(booster.type)"
        >
          <div header class="text-right">
            <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
          </div>
        </semmie-content-card>
      }
      @case (BoosterType.REFERRED) {
        <semmie-content-card
          i18n-title="@@boosters.types.referred.title"
          title="Friends"
          i18n-description="@@boosters.types.referred.description"
          description="How nice, you joined Semmie through one of your friends and have almost earned {{ rewardTextDescription$$() }}!"
          [image]="ctx.image"
          i18n-cta="@@boosters.types.referred.button"
          cta="Deposit a minimum of {{ minimumPayment | semmieCurrency: false : 'decimalsIfAvailable' }}"
          [ctaDisabled]="ctx.buttonDisabled ?? false"
          (ctaInteraction)="primaryCta(booster.type)"
        >
          <div header class="text-right">
            <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
          </div>
        </semmie-content-card>
      }
      @case (BoosterType.REVIEW) {
        <semmie-content-card
          i18n-title="@@boosters.types.review.title"
          title="Write a review"
          i18n-description="@@boosters.types.review.description"
          description="Enthusiastic about Semmie? Share your experiences and receive the eternal gratitude of the Semmie Team!"
          [image]="ctx.image"
          i18n-cta="@@boosters.types.review.button"
          cta="Place review"
          i18n-ctaSecondary="@@boosters.types.review.link"
          ctaSecondary="It may take a while for your review to be noticed"
          [ctaDisabled]="ctx.buttonDisabled ?? false"
          (ctaInteraction)="primaryCta(booster.type)"
        >
          <div header class="text-right">
            <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
          </div>
        </semmie-content-card>
      }
    }
  </ng-template>

  <ng-template #itemLayout let-booster>
    <semmie-card>
      <div class="flex items-stretch">
        <div class="grow">
          <semmie-label *ngIf="booster?.title" class="booster-card-title" type="heading" size="h3" [text]="booster?.title"></semmie-label>
          @if (account?.title) {
            <semmie-label class="booster-card-subtitle" size="base_3" [text]="account?.title"></semmie-label>
          } @else {
            <semmie-label class="booster-card-subtitle" size="base_3" i18n-text="@@account.title-unknown" text="New account"></semmie-label>
          }
        </div>
        <div class="self-center">
          <ng-container *ngTemplateOutlet="rewardValue; context: { $implicit: booster }"></ng-container>
        </div>
      </div>
    </semmie-card>
  </ng-template>

  <ng-template #rewardValue let-booster>
    <ng-container [ngSwitch]="booster.rewardKind">
      <span *ngSwitchCase="RewardKind.THANKS" class="booster-card-reward-icon">
        <semmie-icon [icon]="Icon.HEART" size="sm"></semmie-icon>
      </span>
      <semmie-label *ngSwitchDefault class="booster-card-reward" size="base_3" [text]="rewardText$$()"></semmie-label>
    </ng-container>
  </ng-template>
</ng-container>
