import { NgModule } from '@angular/core';
import { AccountGoalComponent } from '@semmie/components/containers/account/goal';
import { SharedModule } from '@semmie/shared/shared.module';

import { GraphComponent } from './graph.component';

@NgModule({
  imports: [SharedModule, AccountGoalComponent],
  exports: [GraphComponent],
  declarations: [GraphComponent],
  providers: [],
})
export class GraphModule {}
