import { Pipe, PipeTransform } from '@angular/core';
import { RiskProfileIdentifier } from '@onyxx/model/risk-profile';

@Pipe({
  name: 'onyxxRiskProfileAdjectiveTranslate',
  standalone: true,
  pure: true,
})
export class OnyxxRiskProfileAdjectiveTranslatePipe implements PipeTransform {
  transform(riskProfile: RiskProfileIdentifier): string {
    switch (riskProfile) {
      case RiskProfileIdentifier.Defensive:
        return $localize`:@@risk-profile.defensive.adjective:Defensive`;
      case RiskProfileIdentifier.Neutral:
        return $localize`:@@risk-profile.neutral.adjective:Neutral`;
      case RiskProfileIdentifier.Offensive:
        return $localize`:@@risk-profile.offensive.adjective:Offensive`;
      case RiskProfileIdentifier.OffensivePlus:
        return $localize`:@@risk-profile.offensive-plus.adjective:Very offensive`;
      case RiskProfileIdentifier.VeryOffensive:
        return $localize`:@@risk-profile.very-offensive.adjective:Very offensive`;
    }
  }
}
