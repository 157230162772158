import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidatorMessage } from '@semmie/models/validators/validator-message';
import { RegExpPatterns } from '@semmie/shared/regexp';

export function prohibitedNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value;

    const containsProhibitedCharacters = RegExpPatterns.PROHIBITED_NAME_CHARACTERS.test(value);

    if (containsProhibitedCharacters) {
      return new ValidatorMessage({
        code: 18,
        message: $localize`:@@validation.name-characters:This field cannot contain special characters.`,
      });
    }

    return null;
  };
}
