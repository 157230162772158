<semmie-card>
  <div class="flex items-stretch">
    <div class="grow">
      <semmie-label *ngIf="referral.name" class="referral-card-title" type="heading" size="h3" [text]="referral.name"></semmie-label>
      <semmie-label
        *ngIf="referral.state !== ReferralState.FINISHED; else accountName"
        class="referral-card-subtitle"
        size="base_3"
        [text]="referralStateLabel$$()"
      ></semmie-label>
      <onyxx-content-to-string (valueChanges)="referralStateLabel$$.set($event)">
        @switch (referral.state) {
          @case (ReferralState.STARTED) {
            <ng-container i18n="@@boosters.referrals.state.started">Started opening account</ng-container>
          }
          @case (ReferralState.ACCOUNT) {
            <ng-container i18n="@@boosters.referrals.state.account">Awaiting deposit</ng-container>
          }
          @case (ReferralState.PAYMENT) {
            <ng-container i18n="@@boosters.referrals.state.payment">Final checks</ng-container>
          }
          @default {
            <ng-container i18n="@@boosters.referrals.state.unknown">Progress unknown</ng-container>
          }
        }
      </onyxx-content-to-string>
      <ng-template #accountName>
        @if (referral.account?.title) {
          <semmie-label class="referral-card-subtitle" size="base_3" [text]="referral.account?.title"></semmie-label>
        } @else {
          <semmie-label class="referral-card-subtitle" size="base_3" i18n-text="@@account.title-unknown" text="New account"></semmie-label>
        }
      </ng-template>
    </div>
    <div class="self-center" [ngSwitch]="referral.state">
      <semmie-progress-circle *ngSwitchCase="ReferralState.STARTED" [value]="25"></semmie-progress-circle>
      <semmie-progress-circle *ngSwitchCase="ReferralState.ACCOUNT" [value]="50"></semmie-progress-circle>
      <semmie-progress-circle *ngSwitchCase="ReferralState.PAYMENT" [value]="75"></semmie-progress-circle>
      <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="referral.rewardKind">
          <semmie-label
            *ngSwitchCase="RewardKind.CASH"
            class="referral-card-reward"
            size="base_3"
            [text]="referral.price | semmieCurrency: true : 'decimalsIfAvailable'"
          ></semmie-label>

          <semmie-label
            *ngSwitchCase="RewardKind.MONTHS_NO_FEE"
            class="referral-card-reward"
            size="base_3"
            [text]="rewardLabel$$()"
          ></semmie-label>
          <onyxx-content-to-string (valueChanges)="rewardLabel$$.set($event)">
            <ng-container i18n="@@boosters.rewards.months-no-fee">{referral.amount, plural,
              =0 {0 months no fee}
              =1.0 {+1 month no fee}
              other {+{{ referral.amount | number: '1.0' }} months no fee}
            }</ng-container>
          </onyxx-content-to-string>
        </ng-container>
      </ng-container>
    </div>
  </div>
</semmie-card>
