<ng-container *ngIf="{ goal: goal$ | async } as ctx">
  @if (isLoaded()) {
    <semmie-card [padding]="false">
      @if (ctx.goal && showRetry === false) {
        @if (ctx.goal.state !== GoalState.INVALID && ctx.goal.graph?.realistic) {
          <div
            class="account-goal-graph-container"
            [class.containsChart]="ctx.goal && showChart"
            *ngIf="ctx.goal?.suggestions ? chartWhenOfftrack : true"
          >
            <div class="goal-header flex">
              <div class="grow">
                <div class="mb-1 flex flex-row">
                  <semmie-label [text]="expectedYear$ | async" size="h5" weight="medium"></semmie-label>
                </div>
                <semmie-label
                  [text]="
                    (lowestResult$ | async | semmieCurrency: false : '1.0-0') +
                    ' - ' +
                    (highestResult$ | async | semmieCurrency: false : '1.0-0')
                  "
                  size="h2"
                  weight="medium"
                ></semmie-label>
              </div>
              <div *ngIf="showStatus && mode === 'default'" class="state flex items-center self-start" [ngClass]="ctx.goal.state">
                <ng-container [ngSwitch]="ctx.goal.state">
                  <ng-container *ngSwitchCase="GoalState.RECALCULATING">
                    <div class="h-10 w-10">
                      <semmie-loading-ripple position="inline"></semmie-loading-ripple>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <span class="icon">
                      <semmie-icon size="xxxxs" [icon]="getIconForState(ctx.goal.state)"></semmie-icon>
                    </span>
                    @switch (ctx.goal.state) {
                      @case (GoalState.ONTRACK) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.ontrack"
                          text="On track"
                        ></semmie-label>
                      }
                      @case (GoalState.OFFTRACK) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.offtrack"
                          text="Off track"
                        ></semmie-label>
                      }
                      @case (GoalState.LEARNING) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.learning"
                          text="Learning"
                        ></semmie-label>
                      }
                      @case (GoalState.ONTRACK) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.recalculating"
                          text="Recalculating"
                        ></semmie-label>
                      }
                    }
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <semmie-goal-chart
              *ngIf="showChart"
              [account]="account"
              [goal]="ctx.goal"
              [datesPension]="datesPension"
              (onScrubHeaderData)="setHeaderData($event)"
            ></semmie-goal-chart>
          </div>
        }
      } @else {
        <div class="no-data">
          <semmie-empty-placeholder
            i18n-text="@@goal.step.details.retry.description"
            text="Requesting the expected plan wasn't successful.<br/>Please try again or contact our customer service."
            [icon]="Icon.GRAPH"
          ></semmie-empty-placeholder>
          <semmie-button size="sm" (onClick)="retryLoading()">
            <semmie-label i18n-text="@@goal.step.details.retry.button" text="Try again"></semmie-label>
          </semmie-button>
        </div>
      }
    </semmie-card>
  } @else {
    <semmie-card [padding]="false">
      <div class="chart-loader">
        <div class="loader">
          <semmie-loading-ripple position="inline"></semmie-loading-ripple>
        </div>
      </div>
    </semmie-card>
  }
</ng-container>
