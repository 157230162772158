import { computed, Injectable, signal } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router, UrlCreationOptions, UrlTree } from '@angular/router';

import { NavController } from '@ionic/angular';

import { filter, map } from 'rxjs/operators';

import { BaseNavigationService } from '@semmie/services/navigation/__abstract/base-navigation.service';
import { AccountRedirectState, AccountsRouteNames, MainRouteNames } from '@onyxx/model/main';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { ConfigService } from '@semmie/services';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonRouteParams } from '@semmie/views/shared/common-route-params.enum';
import { Utils } from '@onyxx/utility/general';
import { Subject } from 'rxjs';
import { NotificationData, NotificationKind, NotificationMessage, PushNotification } from '@onyxx/model/notifications';
import { TransactionQueryParam } from '@semmie/views/account/transactions/constants';
import { AccountRouteNames } from '@semmie/views/account/account.common';
import { SettingsRouteNames } from '@semmie/views/settings/settings.common';
import { CommonQueryParams } from '@semmie/views/shared/common-query-params.enum';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends BaseNavigationService {
  backNavigationBeta$$ = toSignal(this.configService.config$.pipe(map((config) => config.config.features?.backNavigationBeta?.enabled)));

  private animatedWritable$$ = signal<[boolean, null | (() => void)]>([true, null]);
  /**
   * Indicate that navigation animations to be forcefully disabled. This is necessary as a workaround to pop the navigation stack without animation.
   */
  animated$$ = computed(() => this.animatedWritable$$());

  /** Emits when a navigation takes place that wants to skip app startup checks */
  readonly skipStartupChecks$ = new Subject<boolean>();

  constructor(
    private configService: ConfigService,
    private router: Router,
    private navCtrl: NavController,
    private location: Location,
  ) {
    super();
    this.router.events.pipe(filter((r): r is NavigationEnd => r instanceof NavigationEnd)).subscribe((r) => this.addRouteUrl(r.url));
  }

  navigate(commands: Array<any>, extendedOpts?: NavigationOptions, params?: { backwards: boolean }): Promise<any> {
    const opts: Partial<NavigationExtras> = { ...(extendedOpts ?? {}) };

    const nextCommand = this.getCommand(commands);

    if (opts?.replaceUrl) {
      this.allRoutes = [];
    }

    if (params?.backwards) {
      return this.navCtrl.navigateBack(nextCommand, opts);
    }

    return this.navCtrl.navigateForward(nextCommand, opts);
  }

  navigateByUrl(
    url: string | UrlTree | any[],
    extraParams?: NavigationOptions,
    params?: {
      /** Skip startup checks if they are triggered as a result of this navigation */
      skipAppStartupChecks: boolean;
    },
  ) {
    if (params?.skipAppStartupChecks) {
      this.skipStartupChecks$.next(true);
    }
    return this.navCtrl.navigateRoot(url, extraParams);
  }

  async pop(options: {
    defaultBackPath: string[] | UrlTree | unknown[];
    relativeTo?: ActivatedRoute;
    queryParams?: Params;
    animated?: boolean;
  }) {
    if (options.animated === false) {
      await this.changeAnimation(false);
    }
    const result = await this.navCtrl.pop();
    if (options.animated === false) {
      await this.changeAnimation(true);
    }
    if (result) return;

    await this.navCtrl.navigateBack(options.defaultBackPath, {
      relativeTo: options.relativeTo,
      queryParams: options.queryParams,
      animated: options.animated,
    });
  }

  async closeOnboarding(activatedRoute: ActivatedRoute, options?: { action: 'pop' | 'view-account' }) {
    const accountId = activatedRoute.snapshot.paramMap.get(CommonRouteParams.AccountId);
    if (Utils.isNil(accountId)) {
      return this.pop({ defaultBackPath: ['/', MainRouteNames.Accounts], animated: true });
    }
    const navigationState: AccountRedirectState = {
      direction: options?.action === 'view-account' ? 'forward' : 'back',
    };
    return this.navigate([MainRouteNames.Accounts, accountId], { animated: true, state: navigationState }, { backwards: true });
  }

  async navigateToPushNotification(notification: PushNotification) {
    return this.navigationToNotificationHandler(notification, { skipStartupChecks: true });
  }
  async navigationToNotification(notification: NotificationMessage) {
    return this.navigationToNotificationHandler(notification, { skipStartupChecks: false });
  }

  /**
   * @deprecated Use pop instead
   */
  async back() {
    if (this.previousRoute) {
      this.navCtrl.back();
      this.allRoutes.pop();
    } else {
      const prevPath = this.location.path().split('/').slice(0, -1);
      await this.navigate(prevPath, { replaceUrl: true }, { backwards: true });
    }
  }

  /**
   * @deprecated. Navigate to the root yourself
   */
  toDashboard(backwards?: boolean, clearHistory?: boolean): void {
    if (clearHistory) {
      this.navCtrl.navigateRoot(['/', MainRouteNames.Accounts], { replaceUrl: true });
      return;
    }

    if (backwards) {
      this.navCtrl.navigateBack(['/', MainRouteNames.Accounts], { replaceUrl: true });
    } else {
      this.navCtrl.navigateForward(['/', MainRouteNames.Accounts], { replaceUrl: true });
    }
  }

  /**
   * @deprecated Use router.createUrlTree instead
   **/
  createUrlTree(commands: any[], navigationExtras?: UrlCreationOptions): UrlTree {
    return this.router.createUrlTree(commands, navigationExtras);
  }

  private async navigationToNotificationHandler(
    notification: NotificationMessage | PushNotification,
    options: { skipStartupChecks: boolean },
  ) {
    const getUrlTree = (data: NotificationData) => {
      switch (data.kind) {
        case NotificationKind.WithdrawalPaidOut:
          break;
        // BE doesn't know the correct transaction id, so we can't link to the transaction directly. We don't have an action for now.
        // return this.router.createUrlTree([MainRouteNames.Accounts, data.account_id, AccountRouteNames.Transactions], {
        //   queryParams: { [TransactionQueryParam.TransactionId]: data.withdrawal_id },
        // });
        case NotificationKind.DepositReceived:
          return this.router.createUrlTree([MainRouteNames.Accounts, data.account_id, AccountRouteNames.Transactions], {
            queryParams: { [TransactionQueryParam.TransactionId]: data.transaction_id },
          });
        case NotificationKind.InvitationReceived:
          return this.router.createUrlTree([MainRouteNames.Accounts, AccountsRouteNames.Invitation, data.invitation_id]);
        case NotificationKind.AccountCompleted:
        case NotificationKind.ConfirmAgreement:
        case NotificationKind.InvitationAccepted:
        case NotificationKind.InvitationDeclined:
        case NotificationKind.LeiValidated:
          return this.router.createUrlTree([MainRouteNames.Accounts, data.account_id]);
        case NotificationKind.TaskNew:
          return this.router.createUrlTree([MainRouteNames.Settings, SettingsRouteNames.UserTasks, data.task_id]);
        case NotificationKind.MagazineNew:
          return this.router.createUrlTree([MainRouteNames.Magazine, data.magazine_id]);
        case NotificationKind.MessageNew:
          return this.router.createUrlTree([MainRouteNames.Settings, SettingsRouteNames.Inbox], {
            queryParams: { [CommonQueryParams.ItemId]: data.message_id },
          });
        case NotificationKind.InvitationRevoked:
        case NotificationKind.DepositRefunded:
        case NotificationKind.DirectDebitCancelled:
        case NotificationKind.InspectionRightRevoked:
          break;
      }

      if (data?.path) {
        return this.router.parseUrl(data.path);
      }

      return null;
    };

    const urlTree = getUrlTree(notification.data);

    if (Utils.isNil(urlTree)) return;

    if (options.skipStartupChecks) {
      this.skipStartupChecks$.next(true);
    }

    this.navCtrl.navigateForward(urlTree, {
      queryParamsHandling: 'merge',
    });
  }

  private async changeAnimation(value: boolean) {
    await new Promise<void>((resolve) => {
      this.animatedWritable$$.set([value, resolve]);
    });
  }
}
