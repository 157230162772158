import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concatLatestFrom } from '@ngrx/operators';
import { Utils } from '@onyxx/utility/general';
import { InfoModalComponent } from '@semmie/components/containers/modals/info-modal/info-modal.component';
import { ModalSize } from '@semmie/schemas/components/modal';
import { AppService } from '@semmie/services/app/app.service';
import { ModalService } from '@semmie/services/modal/modal.service';
import { Subject, defer, fromEvent, merge, of } from 'rxjs';
import { concatMap, filter, map, mergeAll, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private readonly appService = inject(AppService);
  private readonly triggerModalConnection$$ = new Subject<void>();
  private connectionStatusModal: HTMLIonModalElement | null = null;

  private readonly connectionChange$ = merge([
    fromEvent(window, 'online'),
    fromEvent(window, 'offline'),
    this.triggerModalConnection$$,
  ]).pipe(mergeAll());

  private readonly handleConnectionChanges$ = this.connectionChange$.pipe(
    // wait for the app to be active before handling connection changes
    // When the app is not active, modalService.open pauses until the
    // app becomes active. Therefore, when the app is opened the
    // connection modal is opened and closed even though the network
    // is connected
    concatLatestFrom(() => this.appService.appIsActive$),
    switchMap(([event, appIsReady]) => {
      if (appIsReady) {
        return of(event);
      }

      return this.appService.appIsActive$.pipe(
        filter(Boolean),
        take(1),
        map(() => event),
      );
    }),
    // the concat map will wait for promise to resolve before calling the next one, thereby preventing multiple modals from opening
    concatMap(() => {
      return defer(async () => {
        if (navigator.onLine) {
          await this.connectionStatusModal?.dismiss();
          return;
        }

        // offline
        if (Utils.isNotNil(this.connectionStatusModal)) {
          // the modal is already open
          return;
        }

        // show modal
        const newModal = await this.modalService.open(
          InfoModalComponent,
          {
            componentProps: {
              title: $localize`:@@network.offline-modal.title:No Internet Connection`,
              description: $localize`:@@network.offline-modal.description:It seems you are not connected to the internet. Check your connection and try again.`,
              button: $localize`:@@network.offline-modal.button:Close`,
            },
          },
          { size: ModalSize.Auto },
        );

        newModal.onDidDismiss().then(() => {
          this.connectionStatusModal = null;
        });

        this.connectionStatusModal = newModal;
      });
    }),
  );

  constructor(private modalService: ModalService) {
    this.handleConnectionChanges$.pipe(takeUntilDestroyed()).subscribe();
  }

  onOfflineApiError() {
    this.triggerModalConnection$$.next();
  }
}
