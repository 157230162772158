import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BaseOnboardingService, NavigationCallback } from './__abstract/base-onboarding.service';
import { AccountHelpers } from '@onyxx/model/account';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';

/**
 * !Todo: Change logs to be logged only in debug mode
 */
@Injectable({
  providedIn: 'root',
})
export class SharedOnboardingService extends BaseOnboardingService {
  handleOnboarding(navigateCallback: NavigationCallback): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPerson(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedIdentification(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedStrategy(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPlan(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedFirstPayment(navigateCallback, finished)),
        switchMap((finished) => {
          if (this.accountPerson.isInvitee()) {
            return this.inviteeOnboarding(navigateCallback, finished);
          } else {
            return this.initiatorOnboarding(navigateCallback, finished);
          }
        }),
      )
      .subscribe(() => {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  handleFinishedFirstPayment(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('payment');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'first' }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    if (this.accountPerson.isInvitee()) {
      return this.hasBeenWidValidated();
    }

    return this.hasFinishedSendingInvitation();
  }

  handleInvitationCompleted(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('invite');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, this.account.invitation?.id], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSigning('other');
  }

  handleInviteeHasSigned(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, this.account.invitation?.id], {
        clearOnboardingNavigationStack: true,
      });
      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  handleCompletedWidPayment(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'wid' }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  hasFinishedSummary(): Observable<boolean> {
    return of(AccountHelpers.hasSigned(this.account, this.user?.id));
  }

  handleFinishedSendingInvitation(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('invite');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, { step: InvitationStep.INVITE }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSigning('other');
  }

  private initiatorOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    return this.handleFinishedSendingInvitation(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleInvitationCompleted(navigateCallback, finished)),
      switchMap((finished) => this.handleInviteeHasSigned(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }

  private inviteeOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    return this.handleCompletedWidPayment(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }
}
