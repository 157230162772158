/**
 * Known error responses (probably incomplete)
 */
export interface iBackendFieldResponseDetails {
  error: BackendError;
  value?: string;
  count?: number;
  date?: string;
}

export interface iBackendFieldsErrorResponse {
  code: BackendErrorResponseCode;
  description: string;
  details: Array<Array<iBackendFieldResponseDetails>>;
}

export enum BackendErrorResponseCode {
  UnprocessableEntity = 'unprocessable_entity',
}

export enum BackendError {
  Blank = 'blank',
  DateAfterOrEqualTo = 'date_after_or_equal_to',
  DateBeforeOrEqualTo = 'date_before_or_equal_to',
  Invalid = 'invalid',
  InvalidBsn = 'invalid_bsn',
  InvalidZipCode = 'invalid_zipcode',
  NotADate = 'not_a_date',
  NotANumber = 'not_a_number',
  TooLong = 'too_long',
  TooShort = 'too_short',
}
