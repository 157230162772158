import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Dictionary, IdSelector, MemoizedEntitySelectors, Update } from '@ngrx/entity/src/models';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { PaginationMeta } from '@onyxx/model/pagination';
import { Utils } from '@onyxx/utility/general';
import { updateMetaData } from './update-meta-data';

export interface PaginatedEntityState<T> extends EntityState<T> {
  paginationMeta: PaginationMeta | null;
}

type MemoizedPaginatedEntitySelectors<T, TState> = MemoizedEntitySelectors<T, TState> & {
  selectEntitiesWithMeta: MemoizedSelector<
    TState,
    { data: NonNullable<T>[]; meta: PaginationMeta } | null,
    (
      entities: Dictionary<T>,
      ids: Array<string | number>,
      meta: PaginationMeta | null,
    ) => { data: NonNullable<T>[]; meta: PaginationMeta } | null
  >;
  selectEntity: (id: string) => MemoizedSelector<TState, T | null, (entities: Dictionary<T>) => T | null>;
};

// False positive
// eslint-disable-next-line @ngrx/prefix-selectors-with-select
export const createPaginatedEntityAdapter = <T>(options?: {
  selectId?: IdSelector<T>;
  sortComparer?: false | ((a: T, b: T) => number);
}) => {
  const baseAdapter = createEntityAdapter<T>(options);

  return {
    getInitialState: <TState>(state: TState): TState & PaginatedEntityState<T> =>
      baseAdapter.getInitialState({
        paginationMeta: null,
        ...state,
      }),

    setAll: <TState extends PaginatedEntityState<T>>(entities: T[], paginationMeta: PaginationMeta, state: TState): TState => {
      return {
        ...baseAdapter.setAll(entities, state),
        paginationMeta,
      };
    },

    addNextPage: <TState extends PaginatedEntityState<T>>(entities: T[], paginationMeta: PaginationMeta, state: TState): TState => {
      return {
        ...baseAdapter.addMany(entities, state),
        paginationMeta,
      };
    },

    addOne: <TState extends PaginatedEntityState<T>>(entity: T, state: TState): TState => {
      return {
        ...baseAdapter.addOne(entity, state),
        paginationMeta: updateMetaData(state.paginationMeta, 'increment'),
      };
    },

    removeOne: <TState extends PaginatedEntityState<T>>(id: string, state: TState): TState => {
      const itemInList = Utils.isNotNil(state.entities[id]);
      return {
        ...baseAdapter.removeOne(id, state),
        paginationMeta: itemInList ? updateMetaData(state.paginationMeta, 'decrement') : state.paginationMeta,
      };
    },

    updateOne: <TState extends PaginatedEntityState<T>>(update: Update<T>, state: TState): TState => {
      return {
        ...baseAdapter.updateOne(update, state),
      };
    },

    getSelectors: <TState extends PaginatedEntityState<T>>(
      selectState: (state: TState) => TState,
      selectEntities: Selector<TState, Dictionary<T>>,
      selectIds: Selector<TState, string[] | number[]>,
      selectPaginationMeta: Selector<TState, PaginationMeta | null>,
    ) => {
      return {
        ...baseAdapter.getSelectors(selectState),
        selectEntitiesWithMeta: createSelector(selectEntities, selectIds, selectPaginationMeta, (entities, ids, paginationMeta) => {
          if (Utils.isNil(paginationMeta)) {
            return null;
          }

          return {
            // the entities are not in the correct order, but the IDs are
            data: ids.map((id) => entities[id]).filter(Utils.isNotNil),
            meta: paginationMeta,
          };
        }),
        selectEntity: (id: string) => createSelector(selectEntities, (entities) => entities[id] ?? null),
      } as MemoizedPaginatedEntitySelectors<T, Record<string, unknown>>;
    },
  };
};
