import { ChangeDetectionStrategy, Component, HostBinding, Input, signal } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { RewardKind } from '@semmie/schemas/bi/reward';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { CurrencyModule } from '@semmie/pipes/currency/currency-pipe.module';
import { SharedModule } from '@semmie/shared/shared.module';
import { Reward } from '@semmie/models/bi/reward/reward.model';
import { ContentToStringComponent } from '@onyxx/ui/content-to-string';

@Component({
  standalone: true,
  imports: [SharedModule, CardModule, LabelModule, CurrencyModule, ContentToStringComponent],
  selector: 'semmie-reward-card',
  templateUrl: 'reward-card.component.html',
  styleUrls: ['./reward-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardCardComponent extends BaseComponent {
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() reward: Reward;
  readonly RewardKind = RewardKind;
  readonly rewardLabel$$ = signal<string | null>(null);
}
