import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { Person } from '@semmie/models';
import { Account, AccountHelpers } from '@onyxx/model/account';
import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { iUser } from '@onyxx/model/user';

import { BaseOnboardingService, NavigationCallback } from './__abstract/base-onboarding.service';
import { Utils } from '@semmie/shared/utils';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { MainRouteNames } from '@onyxx/model/main';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';

/**
 * !Todo: Change logs to be logged only in debug mode
 */
@Injectable({
  providedIn: 'root',
})
export class GrandparentOnboardingService extends BaseOnboardingService {
  private currentPersonId: string;
  private child: Person;

  initialize(account: Account, navigateCallback: NavigationCallback, person: Person, user: iUser): void {
    this.account = account;
    this.person = person;
    this.user = user;

    const accountPerson = this.account.people.find((p) => p.id === this.user?.person?.id);
    this.accountPerson = new AccountPerson(accountPerson);

    const child = AccountHelpers.child(this.account);

    if (AccountHelpers.isOwner(this.account)) {
      this.personService
        .getPersonById(child?.id, false, false)
        .pipe(filter(Utils.isNonNullOrUndefined), take(1))
        .subscribe((child) => {
          this.child = child;
          this.handleOnboarding(navigateCallback);
        });
    } else {
      this.handleOnboarding(navigateCallback);
    }
  }

  handleOnboarding(navigateCallback: NavigationCallback): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedGrandparent(navigateCallback, finished)),
        switchMap((finished) => {
          if (this.accountPerson.isInvitee()) {
            return this.inviteeOnboarding(navigateCallback, finished);
          } else {
            return this.initiatorOnboarding(navigateCallback, finished);
          }
        }),
      )
      .subscribe(() => {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated], {
          clearOnboardingNavigationStack: true,
        });
      });
  }

  handleFinishedAccount(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!AccountHelpers.isOnboarding(this.account) && AccountHelpers.isInvested(this.account)) {
      navigateCallback([MainRouteNames.Accounts, this.account.id]);
      return EMPTY;
    }

    if (!finished) {
      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Account]);

      return EMPTY;
    }

    return this.hasFinishedGrandparent();
  }

  handleFinishedGrandparent(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      navigateCallback([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Grandparent,
        this.currentPersonId,
        'child',
        this.child.id,
        {
          step: this.currentStep.value,
        },
      ]);

      return EMPTY;
    }

    return this.hasFinishedSendingInvitation();
  }

  handleInvitationCompleted(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean> {
    if (!finished) {
      this.currentStep.next('invite');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, { step: InvitationStep.INVITE }]);

      return EMPTY;
    }

    return this.hasAccountBeenCreated();
  }

  hasAccountBeenCreated() {
    return of(AccountHelpers.isCreated(this.account));
  }

  handleCheckingState(navigateCallback: NavigationCallback, finished: boolean) {
    if (!finished) {
      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, this.account.invitation?.id], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return of(true);
  }

  hasFinishedGrandparent(person?: AccountPerson): Observable<boolean> {
    const actualPerson = person || this.accountPerson;
    this.currentPersonId = actualPerson.id;

    if (AccountHelpers.isSimpleRole(this.account)) {
      return of(true);
    }

    if (!this.child?.name_casual || !this.child?.birth_at) {
      this.currentStep.next('child');
    } else if (!this.person?.name_first || !this.person.name_middle_and_last || !this.person.birth_at) {
      this.currentStep.next('basic');
    } else if (!this.person.hasCompletedPhone()) {
      this.currentStep.next('phone');
    } else if (!this.person.hasConfirmedPhone()) {
      this.currentStep.next('phone');
    } else {
      return of(true);
    }

    if (!actualPerson || !actualPerson?.finished || !this.child || !this.child.finished_at) return of(false);
    return of(true);
  }

  private initiatorOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    return this.handleFinishedSendingInvitation(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleInvitationCompleted(navigateCallback, finished)),
      switchMap((finished) => this.handleCheckingState(navigateCallback, finished)),
    );
  }

  private inviteeOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    return this.handleFinishedSummary(navigateCallback, finished).pipe(
      // return this.handleCompletedWidPayment(finished).pipe(
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }
}
