import { Account, AccountKindWithAutoIncasso, AccountHelpers } from '@onyxx/model/account';

/**
 * Check permission to make basic changes.
 * e.g. change account name and image
 * @param account
 * @returns
 */
export const canPerformBasicAccountChanges = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  return hasRights;
};

/**
 * Check permission to make complexer changes with high impact.
 * for example changes that could impact amount values, plans/goals or investments
 * @param account
 * @returns
 */
export const canPerformComplexAccountChanges = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

/**
 * Check permission to delete an account.
 * Only possible when account is in onboarding and hasn't value
 * @param account
 * @returns
 */
export const canDeleteAccount = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountHasValue = AccountHelpers.hasValue(account);
  const accountIsOnboarding = AccountHelpers.isOnboarding(account);
  return hasRights && !accountHasValue && accountIsOnboarding;
};

/**
 * Check permission to view an account.
 * Only full or basic rights may view the account when onboarding is completed.
 * @param account
 * @returns
 */
export const mayViewAccount = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const accountIsOnboarding = AccountHelpers.isOnboarding(account);
  const accountIsCreated = AccountHelpers.isCreated(account);
  return (hasRights ? !accountIsOnboarding : accountIsCreated) && !AccountHelpers.isBlockedFromViewingAccount(account);
};

export const mayOnboardAccount = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountIsOnboarding = AccountHelpers.isOnboarding(account);
  const accountIsGrandparent = AccountHelpers.isGrandparent(account);
  return (hasRights && accountIsOnboarding) || (accountIsGrandparent && !AccountHelpers.isCreated(account));
};

export const mayViewDepositButton = (account: Account): boolean => {
  const hasRights = !AccountHelpers.hasReadOnlyRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const makePayments = (account: Account): boolean => {
  const hasRights =
    AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account) || AccountHelpers.hasSimpleRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuityPayout(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const mayViewWithdrawalButton = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuityPayout(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const mayViewPayoutButton = (account: Account): boolean => {
  const hasRights = !AccountHelpers.hasReadOnlyRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = AccountHelpers.isAnnuityPayout(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const canCreateWithdrawals = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuity(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const mayViewTransactions = (account: Account): boolean => {
  const hasRights =
    AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account) || AccountHelpers.hasReadOnlyRights(account);
  return hasRights;
};

export const mayViewDocuments = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  return hasRights;
};

/**
 * Every person may see the account holders
 */
export const mayViewAccountHolders = (): boolean => {
  return true;
};

export const mayChangeChartPlotPreferenceData = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const mayChangeTransactionFilterData = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const mayChangeOrganisationData = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

/**
 * Full rights and basic rights: settings menu is always visble for
 * Simple rights: Direct debits menu item when account is active
 * Read only right: Has no menu items, so no need to show
 */
export const mayViewSettingsMenu = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const exception = AccountHelpers.hasSimpleRights(account) && AccountHelpers.isActive(account);
  return hasRights ? hasRights : exception;
};

export const canChangeWealthPlan = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuityPayout(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const canChangeStrategy = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const canChangeRiskProfile = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const mayViewDirectDebits = (account: Account): boolean => {
  const hasRights =
    AccountHelpers.hasFullRights(account) ||
    AccountHelpers.hasBasicRights(account) ||
    AccountHelpers.hasSimpleRights(account) ||
    AccountHelpers.hasReadOnlyRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = AccountKindWithAutoIncasso.includes(account.kind);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const canMakeDirectDebitsChanges = (account: Account): boolean => {
  const hasRights =
    AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account) || AccountHelpers.hasSimpleRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = AccountKindWithAutoIncasso.includes(account.kind);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const mayViewLinkedAccount = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const canMakeLinkedAccountsChanges = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountIsActive = AccountHelpers.isActive(account);
  return hasRights && accountIsActive;
};

export const canChangeStartMoment = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuityPayout(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const mayViewAnnualSpace = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = AccountHelpers.isAnnuity(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const canChangePensionSituation = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account) || AccountHelpers.hasBasicRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = AccountHelpers.isAnnuity(account) || AccountHelpers.isAnnuityPayout(account);
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const mayViewTerminateAccountMenuItem = (account: Account): boolean => {
  const hasRights = AccountHelpers.hasFullRights(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuity(account) && !AccountHelpers.isAnnuityPayout(account); // TODO(config): retrieve the disallowed types from the config
  return hasRights && accountIsActive && accountTypeAllowed;
};

export const canTerminateAccount = (account: Account): boolean => {
  const hasRights = AccountHelpers.isOwner(account);
  const accountIsActive = AccountHelpers.isActive(account);
  const accountTypeAllowed = !AccountHelpers.isAnnuity(account) && !AccountHelpers.isAnnuityPayout(account); // TODO(config): retrieve the disallowed types from the config
  return hasRights && accountIsActive && accountTypeAllowed;
};
