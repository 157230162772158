import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  isDevMode,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, pairwise, startWith, take, takeUntil, tap } from 'rxjs/operators';

import mustache from 'mustache';

import { TranslateService } from '@ngx-translate/core';
import { FormComponent, InfoModalComponent } from '@semmie/components';
import { AccountConvertModalComponent } from '@semmie/components/containers/modals/account-convert-modal/account-convert-modal.component';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { FormStep } from '@semmie/schemas/bi/form';
import { iFormTimerMessage } from '@semmie/schemas/bi/form/form-timer-message.interface';
import { iDynamicForm, UploadState } from '@semmie/schemas/components/dynamic-form';
import { DynamicFieldType } from '@semmie/schemas/components/dynamic-form/dynamic-form-types';
import { ModalSize } from '@semmie/schemas/components/modal';
import { FormModalShowOn, FormModalType } from '@semmie/schemas/components/modal/form';
import { iInfoModal } from '@semmie/schemas/components/modal/info-modal.interface';
import { ModalService } from '@semmie/services';
import { FormService } from '@semmie/services/form/form.service';
import { InterpolateService } from '@semmie/services/interpolate/interpolate.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Illustration } from '@semmie/shared/globals';
import { UploadDocument } from '@semmie/models/bi/upload-document/upload-document.model';
import { ImpactStyle } from '@capacitor/haptics';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { FormInputComponent } from '@semmie/components/containers/form-input/form-input.component';
import { Utils } from '@onyxx/utility/general';
import { filterNil } from '@onyxx/utility/observables';

/** a collection of the time it took to complete each step */
export type StepDuration = { [stepReference: string]: { duration?: number; startedAt: number } | undefined };

/**
 * Filter the checkbox data (unselected values as falsy) before sending it to an api.
 * !TODO: when submitting the form, or retrieving the final data.
 * */
@Component({
  selector: 'semmie-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
})
export class FormPageComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('form') formList: QueryList<FormComponent>;
  @ViewChild('form', { static: false }) form: FormComponent;

  /**
   * Data that is immediately available.
   * This data is available in all FormPage states (e.g. intro and formsteps).
   */
  @Input() data: any;

  /**
   * The steps that should be used
   */
  @Input() formSteps: Array<iDynamicForm>;

  @Input() timerMessage: (secondsElapsed: number) => iFormTimerMessage;

  /**
   * Used for backend errors. Will be shown underneath the relevant fields if this gets data.
   */
  @Input() errors: any;

  /**
   * Skip all intro's of all steps when this is set.
   */
  @Input() skipIntros: boolean;

  @Input() showQuestionNumber = false;

  @Input() showProgress: boolean;

  @Input() showCloseButton: boolean;

  @Input() leftAction = true;

  @Input() requireChangesToSubmit = false;

  @Input() useModalHeader = false;

  /**
   * Turn of the timer that checks whether the questionnaire was
   * completed too fast.
   */
  @Input({ required: false }) disableTimer = false;

  /**
   * The loading state of the submit/next button
   */
  @Input() set submitButtonLoading(value: boolean) {
    this.loading = value;
  }

  @Input() submitButtonDisabled = false;
  @Input() submitButtonText: string | null = null;

  @Output() onFormSubmitError: EventEmitter<any> = new EventEmitter();
  @Output() onModalClose: EventEmitter<{ modal: string; data: any }> = new EventEmitter();
  @Output() onNextStep: EventEmitter<any> = new EventEmitter();
  @Output() onIntroLeftIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onIntroRightIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onLeftIconClick: EventEmitter<FormStep> = new EventEmitter();
  @Output() onIntroButtonClick: EventEmitter<void> = new EventEmitter();
  @Output() onIntroLinkLabelClick: EventEmitter<void> = new EventEmitter();
  @Output() onCloseButtonClick: EventEmitter<void> = new EventEmitter();
  @Output() onLinkButtonClick: EventEmitter<void> = new EventEmitter();

  readonly Icon = Icon;

  form$: Observable<FormComponent>;

  /**
   * The current step reference.
   */
  step: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * The current step reference.
   *
   * !todo: can be deprecated as it's a duplicate. Currently used for the Events.
   */
  stepReference$$: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * Holds the intro states of all steps.
   */
  formState: Array<{ reference: string; seenIntro: boolean }> = [];

  /**
   * A copy of the current step form definition.
   */
  currentStepForm: iDynamicForm | null = null;

  /**
   * Returns true if the current step is the first step.
   */
  isFirstStep: boolean;

  /**
   * Returns true if the current step is the last step.
   */
  isFinalStep: boolean;

  /**
   * Holds the loading state of the current form.
   *
   * Currently used when uploading, or when it is explicitly set from another component (undesirable).
   */
  loading: boolean;

  hasBeenFilledOutBefore = false;

  /**
   * Current step index.
   */
  currentStepIndex = 0;

  formChanges$: Subject<any> = new Subject();

  private destroy$$: Subject<boolean> = new Subject();
  private requests: Array<Observable<UploadDocument | Error>> = [];

  /**
   * Previous step index.
   */
  private previousStepIndex: number;

  /**
   * Current step reference
   */
  private currentStepReference: string;

  /**
   * Next step reference.
   */
  private nextStepReference: string;

  /**
   * Previous step reference.
   */
  private previousStepReference: string;

  private skipTimer = false;

  get progressionPercentage(): number {
    return (this.currentStepIndex / (this.formSteps?.length - 1 || 0)) * 100;
  }

  /** an object holding the time it took to complete each step */
  private stepDurations: StepDuration = {};

  private readonly MIN_TIME_PER_STEP_MS = isDevMode() ? 30 : 3000;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private platformService: PlatformService,
    private interpolateService: InterpolateService,
    private formService: FormService,
    private modalService: ModalService,
    private translate: TranslateService,
    private hapticFeedbackService: HapticFeedbackService,
    private location: Location,
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$$)).subscribe((paramMap) => {
      const paramStep = paramMap.get('step');
      const step = paramStep ?? this.step.value;

      let stepIndex = this.formSteps.findIndex((s) => s.reference === step);

      if (this.formSteps.length === 1) {
        stepIndex = 0;
      }

      const formStep = this.formSteps[stepIndex];

      if (formStep) {
        this.setStep(stepIndex);
      }

      this.initFormState();
    });

    this.step
      .pipe(
        filter((s) => !!s),
        takeUntil(this.destroy$$),
      )
      .subscribe((step) => {
        if (!step) return;
        const stepIndex = this.formSteps.findIndex((s) => s.reference === step);
        this.setStep(stepIndex);
        this.initFormState();
      });

    this.formChanges$.pipe(distinctUntilChanged(), filterNil(), pairwise(), takeUntil(this.destroy$$)).subscribe(async ([prev, curr]) => {
      await this.displayModals(FormModalShowOn.CHANGE, { prev, curr });
    });
  }

  ngAfterViewInit(): void {
    this.form$ = this.formList.changes.pipe(
      map((fl) => fl.last),
      startWith(this.form),
      takeUntil(this.destroy$$),
    );

    /* // TODO: If the form after it emits first is valid, we can assume that it has been filled before. A better
     solution is needed to know if a form has been submitted before. */
    this.form$.pipe(filterNil(), take(1)).subscribe((form) => {
      this.skipTimer = form.valid === true;
      this.hasBeenFilledOutBefore = form.valid === true;

      this.initializeStepStates(form?.formGroup?.getRawValue());
    });
  }

  ngOnDestroy(): void {
    this.skipTimer = false;
    this.stepDurations = {};
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

  ionViewDidLeave(): void {
    this.ngOnDestroy();
  }

  get destroy$() {
    return this.destroy$$.asObservable();
  }

  /**
   * Check if the form intro should be hidden.
   */
  get formIntroHidden() {
    return this.formState?.find((f) => f?.reference === this.currentStepReference)?.seenIntro ?? true;
  }

  /**
   * Get the currentStep with all (meta)data.
   *
   * returns FormStep
   */
  get currentStep(): FormStep {
    return {
      currentStep: this.currentStepReference,
      previousStep: this.previousStepReference,
      nextStep: this.nextStepReference,
      isFirstStep: this.isFirstStep,
      isFinalStep: this.isFinalStep,
      isIntro: this.isFirstStep && !this.formIntroHidden,
      data: this.form?.formValue,
      timerFinished: this.skipTimer || this.disableTimer || this.hasEnoughTimeSpentOnForm(),
      rawData: this.form?.formGroup?.getRawValue(),
    };
  }

  /**
   * Get all info modals.
   */
  get infoModals(): iInfoModal[] {
    return this.currentStepForm?.infoModals ?? [];
  }

  get buttonText(): string {
    if (this.currentStepForm?.button) return this.currentStepForm.button;
    if (this.isFinalStep) {
      return $localize`:@@wizard.send:Send`;
    }
    return $localize`:@@wizard.next:Next`;
  }

  /**
   * Check if we can step backwards.
   *
   * @returns boolean
   */
  canStepBackwards(): boolean {
    return this.leftAction || !this.isFirstStep;
  }

  /**
   * Check if we can step forward.
   *
   * @returns boolean
   */
  canStepForward(): boolean {
    return this.currentStepIndex !== this.formSteps.length - 1;
  }

  dismissTimerMessage(): void {
    this.skipTimer = true;
  }

  /**
   * Callback after clicking on the button on the intro state.
   */
  onIntroButtonClickHandler(): void {
    this.skipTimer = false;
    this.stepDurations = {};

    if (this.currentStepForm?.fields?.length) {
      this.setIntroState(true);
    }

    if (this.currentStepForm?.intro?.buttonInfoModal) {
      this.openInfoModal(null, this.currentStepForm.intro.buttonInfoModal);
    }

    this.onIntroButtonClick.emit();
  }

  /**
   * Callback after clicking on the link label on the intro state.
   */
  onIntroLinkLabelClickHandler(): void {
    this.onIntroLinkLabelClick.emit();
  }

  /**
   * Callback on form data change.
   *
   * @param data form data
   */
  onFormDataChange(data?: any): void {
    this.initializeStepStates();
    this.formChanges$.next(data);
  }

  /**
   * Callback when stepping forward.
   *
   * @returns void
   */
  async nextStepHandler(): Promise<void> {
    const formValidity = this.form.validate();

    if (!formValidity.valid) return;

    const formValidations = this.formService.parseFormValidationRules(
      this.form?.dynamicForm?.validations ?? [],
      this.form?.getFormValueAndData(),
    );
    const hasFormErrors = this.formService.evalFormValidationRules(formValidations);

    if (hasFormErrors) {
      this.onFormSubmitError.emit(formValidations);
      return;
    }

    const modalIsBlockingFlow = await this.displayModals(FormModalShowOn.SUBMIT);
    if (modalIsBlockingFlow) return;

    const isUploadThatRequireUpload = (f: FormInputComponent) =>
      f.field.type === DynamicFieldType.Upload &&
      f.meetsConditions(f.internalField.conditions) &&
      (f.internalField?.uploadAfterCreation === false || !f.internalField.uploadAfterCreation) &&
      f.uploadComponent.state$$.value !== UploadState.uploaded &&
      Utils.isNotNil(f.uploadComponent.value);

    const isCoverImageSelectorThatRequireUpload = (f: FormInputComponent) =>
      f.field.type === DynamicFieldType.CoverImage &&
      f.coverImageSelector.customFileSelected() &&
      (f.internalField?.uploadAfterCreation === false || !f.internalField.uploadAfterCreation);

    const uploadFields = this.form.fields.toArray().filter((f) => isUploadThatRequireUpload(f) || isCoverImageSelectorThatRequireUpload(f));

    if (uploadFields?.length) {
      this.loading = true;

      uploadFields.forEach((f) => {
        this.requests.push((f.uploadComponent || f.coverImageSelector.upload).upload().pipe(take(1)));
      });

      combineLatest(this.requests)
        .pipe(
          take(1),
          tap((result) => {
            if (result.some((uploadResult) => uploadResult instanceof Error)) throw new Error('Failed to upload documents');
          }),
          tap(() => {
            this.requests = [];
            this.loading = false;
            this.processStepFinishedTime();
            this.onNextStep.emit(this.currentStep);
          }),
          catchError((err) => {
            this.requests = [];
            this.loading = false;

            this.onFormSubmitError.emit(err);
            return EMPTY;
          }),
        )
        .subscribe();
    } else {
      this.processStepFinishedTime();
      this.onNextStep.emit(this.currentStep);
    }
  }

  /**
   * Callback when clicking on the left icon on the intro state.
   */
  onIntroLeftIconClickHandler() {
    this.onIntroLeftIconClick.emit();
  }

  /**
   * Callback when clicking on the right icon on the intro state.
   */
  onIntroRightIconClickHandler(): void {
    this.onIntroRightIconClick.emit(this.currentStep);
  }

  /**
   * Callback when clicking on the left icon on the form state.
   *
   * @returns void
   */
  onLeftIconClickHandler(): void {
    if (!this.skipIntros && this.currentStepForm?.intro) {
      this.setIntroState(false);
      return;
    }

    this.onLeftIconClick.emit(this.currentStep);
  }

  /**
   * Callback when clicking on the right icon on the intro state
   */
  onCloseButtonClickHandler(): void {
    this.onCloseButtonClick.emit();
  }

  /**
   * Callback when clicking on a label link.
   *
   * @param event linkLabel data
   */
  onLinkLabelClick(event: any) {
    switch (event.action) {
      case 'stepForward':
        this.stepForward();
        break;
      case 'stepBackwards':
        this.stepBackwards();
        break;
      default:
        this.onLinkButtonClick.emit();
    }
  }

  /**
   * Step forward.
   *
   * Can be used as a standalone wizard.
   * @param replaceStepParam manually replace the `step` param in the url
   */
  stepForward(options: { replaceStepParam?: boolean } = {}) {
    if (this.canStepForward()) {
      if (options.replaceStepParam) {
        const currentPath = this.location.path().split(';step')[0];
        const queryString = this.location.path().split('?')[1] ?? '';
        // TODO: Use the navigation service rather than manually updating the location
        this.location.replaceState(currentPath + `;step=${this.currentStep.nextStep}`, queryString);
      }

      this.currentStepIndex++;
      this.setStep(this.currentStepIndex);
      return;
    }

    if ((this.skipTimer === false || this.disableTimer == false) && this.timerMessage && !this.hasEnoughTimeSpentOnForm()) {
      const timeDiff = this.calculateTotalTimeSpentOnForm();

      const message = this.timerMessage(Math.floor(timeDiff / 1000));

      this.modalService
        .open(
          InfoModalComponent,
          {
            componentProps: {
              ...message,
              subtitle: message.label,
              image: this.platformService.resolveAssetPath(Illustration.HOURGLASS),
            },
            canDismiss: true,
          },
          { size: ModalSize.Full },
        )
        .then(() => this.hapticFeedbackService.interact(ImpactStyle.Heavy));
      this.modalService.onWillClose$.pipe(take(1)).subscribe(() => {
        this.restartForm();
        this.dismissTimerMessage();
      });
    } else {
      this.skipTimer = false;
      this.stepDurations = {};
    }
  }

  /**
   * Step backwards.
   *
   * Can be used as a standalone wizard.
   * @param replaceStepParam manually replace the `step` param in the url
   */
  stepBackwards(options: { replaceStepParam?: boolean } = {}): void {
    if (!this.canStepBackwards()) return;

    if (options.replaceStepParam) {
      const currentPath = this.location.path().split(';step')[0];
      const queryString = this.location.path().split('?')[1] ?? '';
      // TODO: Use the navigation service rather than manually updating the location
      this.location.replaceState(currentPath + `;step=${this.currentStep.previousStep}`, queryString);
    }

    this.currentStepIndex--;
    this.setStep(this.currentStepIndex);
  }

  /**
   * Set the current form intro state.
   *
   * @param state boolean
   */
  setIntroState(state: boolean) {
    const formStateItem = this.formState.find((f) => f.reference === this.currentStepReference);

    if (formStateItem) {
      formStateItem.seenIntro = state;
      this.cdr.markForCheck();
    }
  }

  /**
   * Check if the conditions are met.
   *
   * @param conditions
   * @returns boolean
   */
  meetsConditions(conditions: Array<string>) {
    return this.formService.meetsConditions(conditions, { ...this.data, ...this.form?.formGroup.getRawValue() });
  }

  private restartForm() {
    this.setStep(0);
  }

  /**
   * Initialize the step with interpolation.
   *
   * !todo: refactor and cleanup
   *
   * @param data data to initialize the steps with
   */
  private initializeStepStates(data?: Record<string, unknown>): void {
    const dataSource = Object.assign({}, this.data, data, this.form?.formGroup?.getRawValue());

    const currentFormStep = this.formSteps[this.currentStepIndex];

    const stepLabel = this.showQuestionNumber
      ? $localize`:@@wizard.question:Question ${this.currentStepIndex + 1}`
      : currentFormStep?.label;
    const stepTitle = currentFormStep?.title;
    const stepDescription = currentFormStep?.description;
    const stepIntro = currentFormStep?.intro;
    const stepFooterText = currentFormStep?.footerText;
    const stepInfoModals = Utils.arrayIsNotEmpty(this.infoModals) ? this.infoModals : null;
    const stepButtonLabel = currentFormStep?.button
      ? currentFormStep.button
      : !this.isFinalStep
        ? $localize`:@@wizard.next:Next`
        : $localize`:@@wizard.send:Send`;

    if (Utils.isNotNil(this.currentStepForm)) {
      if (stepLabel) {
        const interpolated = mustache.render(stepLabel, dataSource);
        const translated = !Utils.isEmptyString(interpolated) ? this.translate.instant(interpolated, dataSource) : '';
        this.currentStepForm.label = translated;
      }

      if (stepTitle) {
        const interpolated = mustache.render(stepTitle, dataSource);
        const translated = !Utils.isEmptyString(interpolated) ? this.translate.instant(interpolated, dataSource) : '';
        this.currentStepForm.title = translated;
      }

      if (stepDescription) {
        const interpolated =
          stepInfoModals && typeof stepDescription === 'string'
            ? this.interpolateService.renderWithInfoModals(stepDescription, dataSource, stepInfoModals)
            : this.formService.interpolateFieldProperty('description', this.formSteps[this.currentStepIndex], dataSource);

        this.currentStepForm.description = interpolated;
      }

      if (stepIntro) {
        const interpolated = this.interpolateService.recursivelyInterpolate(stepIntro, dataSource);
        this.currentStepForm.intro = interpolated;
      }

      if (stepFooterText) {
        const interpolated = mustache.render(stepFooterText, dataSource);
        const translated = !Utils.isEmptyString(interpolated) ? this.translate.instant(interpolated, dataSource) : '';
        this.currentStepForm.footerText = translated;
      }

      if (stepButtonLabel) {
        const interpolated = mustache.render(stepButtonLabel, dataSource);
        const translated = !Utils.isEmptyString(interpolated) ? this.translate.instant(interpolated, dataSource) : '';
        this.currentStepForm.button = translated;
      }
    }

    this.canStepBackwards();
    this.canStepForward();

    this.cdr.markForCheck();
  }

  /**
   * Display the (first) modal for the show specified event
   * @param showOn {FormModalShowOn} the event on which the modal will be displayed
   * @returns {boolean} whether we should block the user to go to the next step
   */
  private async displayModals(showOn: FormModalShowOn, changes?: { prev: any; curr: any }): Promise<boolean> {
    if (!this.form) return false;
    // filter the correct modals, sort them on the blocking property (blocking === higher priority modal)
    const modals = this.form?.fields
      .toArray()
      .filter((f) => {
        if (changes) {
          return (
            f.field.type === DynamicFieldType.Modal &&
            f.meetsConditions(f.field.conditions) &&
            f.conditionValueChanged(
              f.field.conditions,
              changes.prev[this.form?.dynamicForm?.reference],
              changes.curr[this.form?.dynamicForm?.reference],
            ) &&
            f.field.showOn === showOn
          );
        } else {
          return f.field.type === DynamicFieldType.Modal && f.meetsConditions(f.field.conditions) && f.field.showOn === showOn;
        }
      })
      .sort((a, b) => Number(b.field.blocking) - Number(a.field.blocking));

    if (modals?.length) {
      const firstModal = modals[0].field;

      let modal;
      switch (firstModal.modalType) {
        case FormModalType.SELECTION:
          modal = await this.modalService.openSelectionModal(
            { title: firstModal.componentProps.title },
            ModalSize.Auto,
            firstModal.componentProps.options,
          );
          break;
        case FormModalType.INFO:
          modal = await this.openInfoModal(null, firstModal.infoModal);
          break;
        default:
          modal = await this.modalService.open(
            this.getModalComponent(firstModal.modalType),
            this.interpolateService.recursivelyInterpolate(
              {
                componentProps: { ...firstModal.componentProps },
              },
              this.form.formGroup.value,
            ),
            { size: ModalSize.Full },
          );
          break;
      }

      const { data } = await modal.onDidDismiss();

      this.onModalClose.emit({ data, modal: firstModal.name });

      if (FormModalType.INFO === firstModal.modalType) {
        if (Utils.isNotNil(data) && !data) return true;
      }

      return firstModal.blocking || Utils.isNotNil(data) === false;
    } else {
      return false;
    }
  }

  private getModalComponent(type: FormModalType) {
    // switch to be extended later when more modal types will be introduced
    switch (type) {
      case FormModalType.CONVERT_ACCOUNT:
        return AccountConvertModalComponent;
      default:
        return InfoModalComponent;
    }
  }

  /**
   * Sets the step.
   * @param newIndex step index
   */
  private setStep(newIndex: number): void {
    this.currentStepIndex = newIndex;
    this.previousStepIndex = newIndex > 0 ? newIndex - 1 : 0;

    const currentStep = this.formSteps[this.currentStepIndex];
    const previousStep = this.formSteps[this.currentStepIndex - 1];
    const nextStep = this.formSteps[this.currentStepIndex + 1];

    this.isFirstStep = this.currentStepIndex === 0;
    this.isFinalStep = this.currentStepIndex === this.formSteps.length - 1;

    this.currentStepReference = currentStep?.reference ?? null;
    this.previousStepReference = previousStep?.reference ?? null;
    this.nextStepReference = nextStep?.reference ?? null;

    this.stepReference$$.next(this.currentStepReference);

    // make a deep copy of the currentstep
    if (Utils.isNotNil(currentStep)) {
      this.currentStepForm = JSON.parse(JSON.stringify(currentStep));
    }

    this.initializeStepStates();

    this.cdr.markForCheck();

    setTimeout(() => {
      const infomodals = this.elementRef.nativeElement.querySelectorAll('[data-info-modal]');
      infomodals.forEach((modal) => {
        modal.addEventListener('click', this.openInfoModal.bind(this));
      });
    }, 0);

    const hasIntro = Boolean(this.formSteps[this.currentStepIndex]?.intro);

    if (this.currentStep.isFirstStep && hasIntro) {
      this.onIntroButtonClick
        .asObservable()
        .pipe(
          take(1),
          tap(() => this.processStepStartTime()),
        )
        .subscribe();
    } else {
      this.processStepStartTime();
    }
  }

  private processStepStartTime() {
    if (this.stepDurations[this.currentStepReference] === undefined) {
      this.stepDurations[this.currentStepReference] = { startedAt: Date.now() };
    }
  }

  private processStepFinishedTime() {
    const currentStepDuration = this.stepDurations[this.currentStepReference];
    if (currentStepDuration) {
      this.stepDurations[this.currentStepReference] = {
        startedAt: currentStepDuration.startedAt,
        duration: Date.now() - currentStepDuration.startedAt,
      };
    }
  }

  private hasEnoughTimeSpentOnForm() {
    return this.calculateTotalTimeSpentOnForm() >= this.MIN_TIME_PER_STEP_MS * Object.values(this.stepDurations).length;
  }

  private calculateTotalTimeSpentOnForm() {
    return Object.values(this.stepDurations)
      .filter(Utils.isNotNil)
      .reduce((acc, curr) => acc + (curr.duration ?? 0), 0);
  }

  /**
   * Initialize the form state
   */
  private initFormState() {
    this.formState = this.formSteps.map((step, index) => {
      return {
        reference: step.reference,
        seenIntro:
          !this.skipIntros && Utils.isNotNil(step.intro) && this.currentStepIndex > index
            ? true
            : !this.skipIntros && Utils.isNotNil(step.intro) && this.currentStepIndex === index
              ? false
              : true,
      };
    });
  }

  private openInfoModal($event?: any, id?: string) {
    const infoModal = this.infoModals?.find((m) => m.id === (id || $event.target.getAttribute('data-info-modal')));
    if (infoModal) {
      if ($event) {
        this.modalService.open(
          InfoModalComponent,
          {
            componentProps: {
              ...infoModal,
            },
          },
          { size: ModalSize.Auto },
        );
      } else {
        return this.modalService.open(
          InfoModalComponent,
          {
            componentProps: {
              ...infoModal,
            },
          },
          { size: infoModal?.size ?? ModalSize.Auto },
        );
      }
    }
  }
}
