import { PaymentProviderEnum } from '@semmie/schemas/bi/payment';

export const DEFAULT_PAYMENT_MODAL_SELECTION_OPTIONS = [
  {
    id: PaymentProviderEnum.ideal,
    image: 'img/payment/ideal.png',
    disabled: false,
    hidden: false,
  },
  {
    id: PaymentProviderEnum.bancontact,
    image: 'img/payment/bancontact.png',
    disabled: false,
    hidden: false,
  },
  {
    id: PaymentProviderEnum.manual,
    image: 'img/payment/manual.png',
    disabled: false,
    hidden: false,
  },
  {
    id: PaymentProviderEnum.directDebit,
    image: 'img/payment/direct-debit.png',
    route: 'direct-debit',
    disabled: false,
    hidden: false,
  },
] as const;
