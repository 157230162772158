import { Clipboard } from '@capacitor/clipboard';
import { Injectable } from '@angular/core';
import { ToastService } from '@semmie/services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private toastService: ToastService) {}

  copy(text: string, toastMessage = true, entity?: string): void {
    if (toastMessage) {
      this.toastService.show({
        message: entity
          ? $localize`:@@clipboard.copied.custom:${entity} copied to clipboard!`
          : $localize`:@@clipboard.copied.default:Copied to clipboard!`,
      });
    }
    Clipboard.write({ string: text });
  }
}
