<semmie-card>
  <div class="flex items-stretch">
    <div class="grow">
      <semmie-label *ngIf="title" class="reward-card-title" type="heading" size="h3" [text]="title"></semmie-label>
      @if (reward.account.title) {
        <semmie-label class="reward-card-subtitle" size="base_3" [text]="reward.account.title"></semmie-label>
      } @else {
        <semmie-label class="reward-card-subtitle" size="base_3" i18n-text="@@account.title-unknown" text="New account"></semmie-label>
      }
    </div>
    <div class="self-center">
      <ng-container [ngSwitch]="reward.rewardKind">
        <semmie-label
          *ngSwitchCase="RewardKind.CASH"
          class="reward-card-reward"
          size="base_3"
          [text]="reward.amount | semmieCurrency: true : 'decimalsIfAvailable'"
        ></semmie-label>
        <semmie-label
          *ngSwitchCase="RewardKind.MONTHS_NO_FEE"
          class="reward-card-reward"
          size="base_3"
          [text]="rewardLabel$$()"
        ></semmie-label>
        <onyxx-content-to-string (valueChanges)="rewardLabel$$.set($event)">
          <ng-container i18n="@@boosters.rewards.months-no-fee">{reward.amount, plural,
            =0 {0 months no fee}
            =1.0 {+1 month no fee}
            other {+{{ reward.amount | number: '1.0' }} months no fee}
          }</ng-container>
        </onyxx-content-to-string>
      </ng-container>
    </div>
  </div>
</semmie-card>
