import { Injectable, RendererFactory2, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppPreferences, AppState, iAppMeta, NavVisibility, NavVisibilityConfiguration } from '@semmie/schemas';
import { BaseStore } from '@semmie/models/_abstract';
import { Utils } from '@onyxx/utility/general';

const NAV_VISIBLE_PORTRAIT_CLASS = 'navVisible-portrait';
const NAV_VISIBLE_LANDSCAPE_CLASS = 'navVisible-landscape';

@Injectable({
  providedIn: 'root',
})
export class AppStore extends BaseStore<AppState> {
  private renderFactory = inject(RendererFactory2);

  appNavVisibility$ = this.select((store) => store.appNavVisibility);
  appIsActive$ = this.select((store) => store.appIsActive);

  /**
   * App preferences ((re)stored on the device)
   */
  appPreferences$$: BehaviorSubject<AppPreferences | null> = new BehaviorSubject(null);

  /**
   * App Meta information
   */
  appMeta$$: BehaviorSubject<iAppMeta | null> = new BehaviorSubject(null);

  constructor() {
    super({
      appIsActive: true,
      appNavVisibility: {
        portrait: true,
        landscape: true,
      },
    });
  }

  get appMeta$() {
    return this.appMeta$$.asObservable();
  }

  get appPreferences$() {
    return this.appPreferences$$.asObservable();
  }

  updateAppNavVisibility(navVisibility?: NavVisibilityConfiguration): void {
    this.patchState((currentState) => {
      const calculateVisibility = (current: boolean | undefined, request: boolean | NavVisibility | undefined) => {
        if (request === true || request == NavVisibility.Visible) {
          return true;
        }

        if (request === false || request == NavVisibility.Hidden) {
          return false;
        }

        if (Utils.isNotNil(current) && request === NavVisibility.Keep) {
          return current;
        }

        return true;
      };

      return {
        appNavVisibility: {
          portrait: calculateVisibility(currentState?.appNavVisibility.portrait, navVisibility?.portrait),
          landscape: calculateVisibility(currentState?.appNavVisibility.landscape, navVisibility?.landscape),
        },
      };
    });

    const updatedNavVisibility = this.store.getValue()?.appNavVisibility;

    const renderer = this.renderFactory.createRenderer(null, null);

    updatedNavVisibility?.portrait === false
      ? renderer.removeClass(document.body, NAV_VISIBLE_PORTRAIT_CLASS)
      : renderer.addClass(document.body, NAV_VISIBLE_PORTRAIT_CLASS);

    updatedNavVisibility?.landscape === false
      ? renderer.removeClass(document.body, NAV_VISIBLE_LANDSCAPE_CLASS)
      : renderer.addClass(document.body, NAV_VISIBLE_LANDSCAPE_CLASS);
  }

  updateAppIsActive(appIsActive: boolean): void {
    this.patchState({
      appIsActive,
    });
  }
}
