import { Injectable } from '@angular/core';

import { EMPTY, Observable, combineLatest, map, of, switchMap, take } from 'rxjs';

import { BaseOnboardingService, NavigationCallback } from './__abstract/base-onboarding.service';
import { Goal, Person } from '@semmie/models';
import { Account, AccountHelpers } from '@onyxx/model/account';
import { iUser } from '@onyxx/model/user';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';

@Injectable({
  providedIn: 'root',
})
export class AnnuityOnboardingService extends BaseOnboardingService {
  override initialize(
    account: Account,
    navigateCallback: NavigationCallback,
    person?: Person | undefined,
    user?: iUser | undefined,
    goal?: Goal | undefined,
  ): void {
    super.initialize(account, navigateCallback, person, user, goal);
  }

  override handleOnboarding(navigateCallback: NavigationCallback): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPerson(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedIdentification(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedStrategy(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPlan(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedFirstPayment(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
      )
      .subscribe(() => {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  override handleFinishedStrategy(navigateCallback: NavigationCallback, finished: boolean) {
    if (!finished) {
      this.currentStep.next('strategy');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Strategy], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedAccountPlan();
  }

  override handleFinishedPlan(navigateCallback: NavigationCallback, finished: boolean) {
    if (!finished) {
      this.currentStep.next('plan');

      navigateCallback(
        [
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.ValueTransfer,
          ...(AccountHelpers.isAnnuityPayout(this.account) ? ['overview'] : []),
        ],
        {
          clearOnboardingNavigationStack: true,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedFirstPayment();
  }

  override handleFinishedFirstPayment(navigateCallback: NavigationCallback, finished: boolean) {
    if (!finished) {
      this.currentStep.next('payment');

      navigateCallback(
        [
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.Payment,
          { step: AccountHelpers.isAnnuityPayout(this.account) ? 'wid' : 'first' },
        ],
        {
          clearOnboardingNavigationStack: true,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  override hasFinishedAccountPlan(): Observable<boolean> {
    const finishedPlan$ = of(AccountHelpers.hasFinishedPlan(this.account));
    if (AccountHelpers.isAnnuityPayout(this.account)) {
      const payoutDefined$ = this.payoutFacade.loadActionDone$.pipe(
        switchMap(() => this.payoutFacade.payout$),
        take(1),
        map((payout) => !!payout),
      );

      this.payoutFacade.dispatchLoad();
      return combineLatest([payoutDefined$, finishedPlan$]).pipe(map(([payoutDefined, finishedPlan]) => finishedPlan && payoutDefined));
    }

    return finishedPlan$;
  }
}
