import { Component, inject, LOCALE_ID, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { exhaustMap, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';

import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';

import { updateLocale } from 'moment';

import { AppRateService, AppService, ConfigService, ModalService, NavigationService, SentryService } from '@semmie/services';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { combineLatest, of, Subject, NEVER, firstValueFrom, asyncScheduler } from 'rxjs';
import { ResourcesService } from '@semmie/services/resources/resources.service';

import { ThemeService } from '@semmie/services/theme/theme.service';
import { AuthFacade } from '@onyxx/store/auth';
import { UserStoreFacade } from '@semmie/store/user';
import { PushNotificationsFacade } from '@onyxx/store/push-notifications';
import { NotificationsFacade } from '@onyxx/store/notifications';
import { BiometricsStoreFacade } from '@onyxx/store/biometrics';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { concatLatestFrom } from '@ngrx/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppLoadingStatusStoreFacade } from '@onyxx/store/app-loading-status';
import { OpenTasksService } from '@semmie/services/open-tasks';
import { HighlightsFacade } from '@onyxx/store/highlights';
import { filterNil } from '@onyxx/utility/observables';
import { AppRouteNames } from '@onyxx/model/main';
import { HighlightsModalComponent } from '@onyxx/feature/highlights';
import { ModalSize } from '@semmie/schemas/components/modal';
import { UserTaskStoreFacade } from '@onyxx/store/user-task';
import { MaintenanceComponentModes, MaintenanceComponentParams } from '@semmie/views/maintenance/maintenance.component';
import { Utils } from '@onyxx/utility/general';

const KEYBOARD_OPEN_CLASS = 'keyboard-is-open';

@Component({
  selector: 'semmie-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly authFacade = inject(AuthFacade);
  private readonly openTasksService = inject(OpenTasksService);
  private readonly highlightsFacade = inject(HighlightsFacade);
  private readonly modalService = inject(ModalService);
  private readonly appRateService = inject(AppRateService);
  private readonly userStoreFacade = inject(UserStoreFacade);

  readonly appLocale = inject(LOCALE_ID);
  private readonly appLoadingStatusStoreFacade = inject(AppLoadingStatusStoreFacade);

  private readonly hasUncompletedAppOnboarding$ = this.appService
    .getUncompletedAppOnboarding()
    .pipe(map((uncompletedAppOnboarding) => uncompletedAppOnboarding.length > 0));

  /**
   * The overlay should not be shown while checking biometrics or if the permissions pop-over is open for biometrics or push notifications
   * If one of these processes is started, the overlay should not be shown until the app is active again
   */
  private readonly hideOverlayWithExpectedInactiveApp$ = combineLatest([
    this.appService.appIsActive$,
    this.pushNotificationsFacade.requestPermissionBusy$,
    this.biometricsStoreFacade.permissionRequestBusy$,
    this.biometricsStoreFacade.authenticationBusy$,
  ]).pipe(
    exhaustMap(([appIsActive, notificationPermissionBusy, biometricsPermissionBusy, authenticationBusy]) => {
      if (!appIsActive && (notificationPermissionBusy || biometricsPermissionBusy || authenticationBusy)) {
        return NEVER.pipe(startWith(true), takeUntil(this.appService.appIsActive$.pipe(filter((appIsActive) => appIsActive))));
      }

      return of(false);
    }),
  );

  private skipAppStartupChecks$$ = toSignal(this.navigationService.skipStartupChecks$, { initialValue: false });

  /**
   * On mobile we show an overlay when the app is taken into the background
   */
  readonly showAppOverlay$ = combineLatest([
    this.appService.appIsActive$,
    this.authFacade.isAuthenticated$,
    this.authFacade.appSecured$,
    this.authFacade.appSecureInitializing$,
    this.hasUncompletedAppOnboarding$,
    this.hideOverlayWithExpectedInactiveApp$,
  ]).pipe(
    map(
      ([
        appIsActive,
        isAuthenticated,
        appSecured,
        appSecureInitializing,
        hasUncompletedAppOnboarding,
        hideOverlayWithExpectedInactiveApp,
      ]) =>
        this.platformService.isApp &&
        (appSecureInitializing ||
          (!appIsActive && !hasUncompletedAppOnboarding && isAuthenticated && appSecured && !hideOverlayWithExpectedInactiveApp)),
    ),
  );

  readonly loading$ = this.appLoadingStatusStoreFacade.loading$;

  /**
   * On mobile when the app is in the background, push notifications are not delivered unless
   * the user clicks on it. Therefore, we need to reload unread counts when the app is brought
   * into the foreground
   */
  readonly reloadUnreadCountsOnAppActive$ = this.appService.appIsActive$.pipe(
    concatLatestFrom(() => this.authFacade.isAuthenticated$),
    filter(([appIsActive, isAuthenticated]) => appIsActive && isAuthenticated),
    tap(() => {
      this.notificationsFacade.load();
      this.userStoreFacade.load();
    }),
    switchMap(() => this.userStoreFacade.loading$),
    filter((loading) => !loading),
    switchMap(() => this.userStoreFacade.user$.pipe(filterNil(), take(1))),
    map((user) => {
      if (Utils.isNotNil(user.task_count)) {
        this.userTaskStoreFacade.dispatchUpdateTaskCount(user.task_count);
      }
    }),
  );

  private destroy$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private navigationService: NavigationService,
    private platformService: PlatformService,
    private resourcesService: ResourcesService,
    private themeService: ThemeService,
    private pushNotificationsFacade: PushNotificationsFacade,
    private notificationsFacade: NotificationsFacade,
    private biometricsStoreFacade: BiometricsStoreFacade,
    private renderer: Renderer2,
    private gtmService: GoogleTagManagerService,
    private sentryService: SentryService,
    private configService: ConfigService,
    private userTaskStoreFacade: UserTaskStoreFacade,
  ) {
    // setup moment locale
    updateLocale(this.appLocale, {
      monthsShort:
        this.appLocale === 'nl' ? ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'] : undefined,
    });

    this.themeService.initialize();
    this.resourcesService.initializeExternalTranslations(this.appLocale);

    if (this.platformService.isApp) {
      this.hookKeyboardEvents();
    }

    if (this.platformService.isiOS && this.platformService.isApp) {
      SuppressLongpressGesture.activateService();
    }

    this.appService.getAppPreferences().pipe(take(1)).subscribe();

    this.reloadUnreadCountsOnAppActive$.pipe(takeUntilDestroyed()).subscribe();
  }

  async ngOnInit() {
    /**
     * Add Google Tag Manager to the DOM
     * - Only in production & web environment
     */
    if (this.platformService.isWeb) {
      this.gtmService.addGtmToDom().catch((error) => {
        // Instead of a normal unhandled error in Sentry, the error will be reported as a GTM error
        // And since the GTM initialization can be caused by plugins or cookie blockers, it is not
        // necessary an error.
        this.sentryService.captureMessage('Failed to load Google Tag Manager', { error });
      });
    }
    /**
     * Check if there's a (config) maintenance and stop any code from executing.
     */
    const maintenanceMode = await firstValueFrom(
      this.configService.config$.pipe(map((config) => config.config.features.maintenance.enabled)),
    );
    if (maintenanceMode) {
      this.navigationService.navigate([AppRouteNames.Maintenance], {
        queryParams: { [MaintenanceComponentParams.Mode]: MaintenanceComponentModes.Manual },
        replaceUrl: true,
      });
      return;
    }

    /**
     * Wait for app to be secured before checking startup checks
     */
    await firstValueFrom(this.authFacade.appSecured$.pipe(filter((secure) => secure === true)));
    if (this.platformService.isApp) {
      await firstValueFrom(this.hasUncompletedAppOnboarding$.pipe(filter((uncompletedAppOnboarding) => !uncompletedAppOnboarding)));
    }

    if (this.skipAppStartupChecks$$()) {
      return;
    }

    /**
     * Only continue if it's a regular user
     * - If the user is an advisor, there's no need to continue and do startup checks
     * Dev note: At the moment this check is never reached if advisors login via Advisor Portal due to the current authFacade secured check from above
     */
    await firstValueFrom(this.userStoreFacade.user$.pipe(filterNil()));
    const isAdvisor = await firstValueFrom(this.userStoreFacade.isAdvisor$);
    if (isAdvisor) return;

    const taskCountCritical = await firstValueFrom(this.userStoreFacade.taskCountCritical$.pipe(filterNil()));
    // If there are no critical tasks, show the startup checks
    if (taskCountCritical == 0) {
      this.handleStartupChecks();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hookKeyboardEvents() {
    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      document.documentElement.style.setProperty('--keyboard-height', info.keyboardHeight + 'px');
      this.renderer.addClass(document.body, KEYBOARD_OPEN_CLASS);

      setTimeout(() => {
        document.activeElement?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
      }, 125);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      document.documentElement.style.setProperty('--keyboard-height', '0px');
      this.renderer.removeClass(document.body, KEYBOARD_OPEN_CLASS);
    });
  }

  /**
   * Handle modals/prompts/tasks to show at startup of the app
   */
  private async handleStartupChecks(): Promise<void> {
    const urgentTaskCount = await firstValueFrom(
      this.userTaskStoreFacade.urgentTasks$.pipe(
        filterNil(),
        map((tasks) => tasks.length),
      ),
    );
    if (urgentTaskCount > 0) {
      this.navigationService.navigate(['/', AppRouteNames.UrgentTasks]);
      return;
    }

    // If there are normal tasks, show the pending task modal
    const taskCountNormal = await firstValueFrom(this.userStoreFacade.taskCountNormal$.pipe(filterNil()));
    if (taskCountNormal > 0) {
      this.openTasksService.checkTasks();
      return;
    }

    // If there are highlights, show the highlights modal
    const highlightIds = await firstValueFrom(this.highlightsFacade.highlightIds$);
    if (highlightIds.length > 0) {
      this.showHighlightModal(highlightIds);
      return;
    }

    // Show the app rate modal if there are no open tasks or highlights
    // Checks for prompting the app rate modal is done in the service
    asyncScheduler.schedule(() => this.appRateService.requestStoreReview(), 500);
  }

  private async showHighlightModal(highlightIds: string[]) {
    if (highlightIds.length <= 0) return;
    const isAppLayout = await firstValueFrom(this.platformService.appLayout$);
    const modal = await this.modalService.open(HighlightsModalComponent, {}, { size: isAppLayout ? ModalSize.Full : ModalSize.Auto });
    await modal.onDidDismiss();
    this.highlightsFacade.dispatchMarkAsRead(highlightIds);
  }
}
