import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concatLatestFrom } from '@ngrx/operators';
import { PushNotification } from '@onyxx/model/notifications';
import { AuthFacade } from '@onyxx/store/auth';
import { NotificationsFacade } from '@onyxx/store/notifications';
import { PushNotificationConfig, PushNotificationsFacade } from '@onyxx/store/push-notifications';
import { UserTaskStoreFacade } from '@onyxx/store/user-task';
import { Utils } from '@onyxx/utility/general';
import { ConfigService } from '@semmie/services/config/config.service';
import { NavigationService } from '@semmie/services/navigation/navigation.service';
import { ConfigStore } from '@semmie/store';
import { UserStoreFacade } from '@semmie/store/user';
import { filter, firstValueFrom, map, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PushNotificationsConfigService implements PushNotificationConfig {
  private readonly authFacade = inject(AuthFacade);
  private readonly configStore = inject(ConfigStore);
  private readonly configService = inject(ConfigService);
  private readonly notificationsFacade = inject(NotificationsFacade);
  private readonly navigationService = inject(NavigationService);
  private readonly pushNotificationsFacade = inject(PushNotificationsFacade);
  private readonly userStoreFacade = inject(UserStoreFacade);
  private readonly userTaskStoreFacade = inject(UserTaskStoreFacade);

  private readonly notificationsEnabled$ = this.authFacade.isAuthenticated$.pipe(
    switchMap((isAuthenticated) => {
      if (!isAuthenticated) {
        return of(false);
      }
      return this.userStoreFacade.readyNotification$.pipe(
        switchMap(() => {
          return this.userStoreFacade.user$;
        }),
        map((user) => (this.configStore.features?.notificationPanel?.enabled || user?.metaData?.notificationPanelEnabled) ?? false),
      );
    }),
  );

  constructor() {
    this.updateDataWhenNotificationReceived();
  }

  /**
   * Client portal specific push notification action handler
   */
  async handlePushNotificationAction(notification: PushNotification) {
    const enabled = await firstValueFrom(this.notificationsEnabled$);
    if (!enabled) return;

    if (notification.data?.notification_id) {
      this.notificationsFacade.markAsRead([notification.data.notification_id]);
    }

    if (this.navigationService.backNavigationBeta$$()) {
      await this.navigationService.navigateToPushNotification(notification);
    } else {
      const path = notification.data?.path;

      if (Utils.isNotNil(path)) {
        const [mainPath, splitQueryParams] = path.split('?');
        const queryParams: { [key: string]: string } = {};
        if (path.includes('?')) {
          const params = new URLSearchParams(splitQueryParams);
          if (params.size > 0) {
            params.forEach((value, key) => {
              queryParams[key] = value;
            });
          }
        }

        this.navigationService.navigateByUrl(
          mainPath,
          {
            queryParamsHandling: 'merge',
            queryParams,
            replaceUrl: true,
          },
          { skipAppStartupChecks: true },
        );
      }
    }
  }

  updateDataWhenNotificationReceived() {
    this.pushNotificationsFacade.notifications$
      .pipe(
        takeUntilDestroyed(),
        concatLatestFrom(() => this.notificationsEnabled$),
        filter(([, enabled]) => {
          return enabled;
        }),
      )
      .subscribe(([notification]) => {
        this.notificationsFacade.dispatchUpdateUnreadCount(Number(notification.data.notification_unread_count)); // to update unread notifications counter

        if (notification.data.task_count) {
          this.userTaskStoreFacade.dispatchUpdateTaskCount(notification.data.task_count);
          this.userStoreFacade.dispatchUpdateTaskCounts(notification.data.task_count);
        }
      });
  }
}
