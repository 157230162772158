/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@semmie/components/_abstract';
import { Goal, Person } from '@semmie/models';
import { Icon } from '@semmie/schemas';
import { GoalFocus } from '@semmie/schemas/bi/goal/goal-focus.enum';
import { GoalState } from '@semmie/schemas/bi/goal/goal-state.enum';
import { iGraphFieldMode } from '@semmie/schemas/components/dynamic-form';
import { AccountsService, ModalService, NavigationService } from '@semmie/services';
import { PersonService } from '@semmie/services/person/person.service';
import moment from 'moment';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, interval, Observable, of, Subject } from 'rxjs';
import { take, switchMap, tap, map, shareReplay, finalize, startWith, takeWhile, exhaustMap } from 'rxjs/operators';
import { Polling } from '@semmie/shared/polling';
import { UserStoreFacade } from '@semmie/store/user';
import { concatLatestFrom } from '@ngrx/operators';
import { Account, AccountKind, AccountPermissions, AccountHelpers } from '@onyxx/model/account';
import { AccountStoreFacade } from '@onyxx/store/account';
import { filterNil } from '@onyxx/utility/observables';
import { GoalFacade } from '@onyxx/store/goal';
import { Utils } from '@onyxx/utility/general';
import { GoalChartHeaderData, GoalChartModule } from '@semmie/components/containers/account/goal/goal-chart';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CardModule, LabelModule, LoadingRippleModule } from '@semmie/components';
import { CurrencyModule } from '@semmie/pipes/currency/currency-pipe.module';
import { SharedModule } from '@semmie/shared/shared.module';
import { CommonModule } from '@angular/common';

/** Do NOT use barrel file for these imports */
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { EmptyPlaceholderComponent } from '@semmie/components/presentational/core/empty-placeholder/empty-placeholder.component';

@Component({
  standalone: true,
  selector: 'semmie-account-goal',
  imports: [
    CommonModule,
    SharedModule,
    CardModule,
    GoalChartModule,
    LabelModule,
    IconComponent,
    ButtonModule,
    LoadingRippleModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyModule,
    EmptyPlaceholderComponent,
  ],
  templateUrl: 'account-goal.component.html',
  styleUrls: ['./account-goal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountGoalComponent extends BaseComponent implements OnInit, OnDestroy {
  private customGoal$$ = new BehaviorSubject<Goal | undefined>(undefined);
  private headerData$$ = new BehaviorSubject<GoalChartHeaderData | null>(null);

  /** Show the goal chart */
  @Input() showChart = true;
  /** Show the graph when offtrack */
  @Input() chartWhenOfftrack = true;
  /** Display mode, used within a form or default */
  @Input() mode: iGraphFieldMode = 'default';

  @Input() withPolling = true;

  /** Use a specified goal to display */
  @Input() set customGoal(value: Goal | undefined) {
    this.customGoal$$.next(value);
  }

  /** Show the goal current status */
  @Input() showStatus = true;

  // Enums
  readonly GoalState = GoalState;
  readonly Icon = Icon;

  readonly AccountHelpers = AccountHelpers;
  readonly AccountPermissions = AccountPermissions;

  isLoaded = signal(false);

  // Properties (public)
  account: Account;
  destroy$: Subject<boolean> = new Subject();
  retryLoading$$: Subject<boolean> = new Subject();
  showRetry = false;
  noData = false;

  goal$ = combineLatest([
    this.goalFacade.goal$.pipe(
      map((goal) => {
        return Utils.isNil(goal) ? null : new Goal(goal);
      }),
    ),
    this.customGoal$$.asObservable(),
  ]).pipe(
    map(([accountGoal, customGoal]) => (customGoal ? customGoal : accountGoal)),
    tap((goal) => {
      // Legacy reasons, further refactors and untangle from the other components needed, then this can be extracted as well
      if (Utils.isNil(goal)) {
        this.noData = true;
      } else {
        this.noData = false;
        this.setLoaded(true);
      }
    }),
    filterNil(),
    // TODO: with a proper state management, get this polling out of here.
    exhaustMap((goal: Goal) => {
      if (goal.recalculating && this.withPolling) {
        return interval(Polling.RECALCULATING_ACCOUNT_GOAL_INTERVAL).pipe(
          exhaustMap(() => this.accountsService.getAccountGoal(this.account?.id, true).pipe(take(1))),
          takeWhile((retrievedGoal) => retrievedGoal.recalculating, true),
          startWith(goal),
        );
      } else {
        return of(goal);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /**
   * When scrubbing show scrub data else:
   * Retrieve the last date in the graph data and display in the YYYY format
   */
  expectedYear$ = combineLatest([this.goal$, this.headerData$$.asObservable()]).pipe(
    map(([goal, headerData]): string => {
      /** While scrubbing */
      if (headerData?.timestamp) {
        if (this.account.kind === AccountKind.ANNUITY && this.datesPension) {
          const selectedYearFromNow = Number(moment.unix(headerData.timestamp / 1000).diff(moment(), 'years'));
          const currentAge = moment().diff(moment(this.datesPension.birth_at), 'years');
          const ageAtSelection = (currentAge + selectedYearFromNow).toString();

          return $localize`:@@goal-chart.expected-at-age:Expected result at age ${ageAtSelection}`;
        }
        const yearAtSelection = moment
          .unix(headerData.timestamp / 1000)
          .format('YYYY')
          .toString();
        return $localize`:@@goal-chart.expected-in:Expected result in ${yearAtSelection}`;
      }

      /** When account type "annuity" */
      if (goal.annuity_ends_at_age || this.account.kind === AccountKind.ANNUITY) {
        return $localize`:@@goal-chart.goal-at-age:Investment goal at age ${goal.getPensionAge(this.datesPension)}`;
      }

      let endDate;
      if (goal.state === GoalState.LEARNING) {
        /** When in learning mode */
        const lastGraphItemDate = Utils.isNotNil(goal.graph?.realistic)
          ? moment(goal.graph.realistic[goal.graph.realistic.length - 1].date)
          : moment().add(30, 'years');
        endDate = goal.ends_at ? moment(goal.ends_at).format('YYYY').toString() : lastGraphItemDate.format('YYYY').toString();
      } else if (goal.focus === GoalFocus.AMOUNT && goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        /** When goal has focus type "amount" */
        endDate = moment(goal.graph.realistic[goal.graph.realistic.length - 1].date)
          .format('YYYY')
          .toString();
      } else if (goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        /** Else show goal in YYYY */
        endDate = moment(goal.graph.realistic[goal.graph.realistic.length - 1].date)
          .format('YYYY')
          .toString();
      }

      return endDate ? $localize`:@@goal-chart.goal-in:Investment goal in ${endDate}` : '';
    }),
  );

  /**
   * When scrubbing show scrub data else:
   * Retrieve the lowest realistic amount that could be reached
   */
  lowestResult$ = combineLatest([this.goal$, this.headerData$$.asObservable()]).pipe(
    map(([goal, headerData]) => {
      if (headerData?.min) {
        return parseInt(headerData.min.toString(), 10);
      }

      if (goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        return parseInt(goal.graph.realistic[goal.graph.realistic.length - 1].from.toString(), 10);
      }

      return 0;
    }),
  );

  /**
   * When scrubbing show scrub data else:
   * Retrieve the highest realistic amount that could be reached
   */
  highestResult$ = combineLatest([this.goal$, this.headerData$$.asObservable()]).pipe(
    map(([goal, headerData]) => {
      if (headerData?.max) {
        return parseInt(headerData.max.toString(), 10);
      }

      if (goal.graph?.realistic?.[goal.graph.realistic.length - 1]) {
        return parseInt(goal.graph.realistic[goal.graph.realistic.length - 1].to.toString(), 10);
      }
      return 0;
    }),
  );

  // temp
  datesPension: any;

  constructor(
    private accountsService: AccountsService,
    private accountStoreFacade: AccountStoreFacade,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private personService: PersonService,
    private userFacade: UserStoreFacade,
    private goalFacade: GoalFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /** Close any modal currently opened */
  closeModal(): void {
    this.modalService.close();
  }

  /**
   * Return the corresponding state Icon
   * @param state GoalState
   */
  getIconForState(state: GoalState): Icon {
    switch (state) {
      case GoalState.ONTRACK:
        return Icon.CHECKMARK;
      case GoalState.OFFTRACK:
        return Icon.EXCLAMATION;
      case GoalState.LEARNING:
        return Icon.ATOM;
      default:
        return Icon.QUESTIONMARK;
    }
  }

  /**
   * Retrieve the latest (non recalculating) goal
   * @param loader show a loader covering the entire goal
   * @param refresh refresh cached goal
   * @param loadGoal quickfix to prevent retrieving goal in a plan flow for advisor
   */
  retrieveGoal(loader = true, refresh?: boolean, loadGoal = true): Observable<Goal> {
    this.showRetry = false;
    if (!loadGoal) {
      this.setLoaded(true);
      return EMPTY;
    } else if (loader) {
      this.setLoaded(false);
    }

    return this.accountStoreFacade.account$.pipe(
      filterNil(),
      switchMap((account) => this.accountsService.getAccountGoal(account.id, refresh)),
      finalize(() => {
        if (loader) {
          this.setLoaded(true);
        }
      }),
      take(1),
    );
  }

  /**
   * Return the user person or defined person by id
   */
  retrieveUserPerson(): Observable<Person> {
    let observable: Observable<Person | undefined | null>;

    if (this.account.kind == AccountKind.ANNUITY) {
      observable = this.personService.getPersonById(AccountHelpers.owners(this.account).find((p) => p.position === 1)?.id).pipe(take(1));
    } else {
      observable = this.userFacade.user$.pipe(
        switchMap((user) => {
          return this.personService.getPersonById(user?.person?.id).pipe(take(1));
        }),
        take(1),
      );
    }

    return observable.pipe(
      tap((person: Person) => {
        if (person) this.datesPension = person.datesPension;
        this.markForCheck();
      }),
    );
  }

  retryLoading(): void {
    this.showRetry = false;
    this.setLoaded(false);

    this.retryLoading$$.next(true);
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  /**
   * Navigate to specified path
   * @param path string[]
   * @param closeModal close modals when navigating
   */
  navigateTo(path: string[], closeModal = false): void {
    if (!path) return;
    this.navigationService.navigate(path, { relativeTo: this.activatedRoute });
    if (closeModal) {
      this.closeModal();
    }
  }

  /**
   * Set the header data when scrubbing
   * @param data { max: number; min: number; timestamp: number }
   */
  setHeaderData(data: GoalChartHeaderData | null): void {
    this.headerData$$.next(data);
  }

  setLoaded(value: boolean): void {
    this.isLoaded.set(value);
    this.markForCheck();
  }

  private initialize(): void {
    this.accountStoreFacade.account$
      .pipe(
        filterNil(),
        take(1),
        tap((account) => {
          this.account = account;
        }),
        concatLatestFrom(() => this.userFacade.isAdvisor$),
        switchMap(([account, isAdvisor]) =>
          forkJoin([
            this.retrieveUserPerson().pipe(take(1)),
            this.retrieveGoal(true, false, !isAdvisor && AccountHelpers.hasFinishedPlan(account)).pipe(take(1)),
          ]),
        ),
      )
      .pipe(take(1))
      .subscribe();
  }
}
