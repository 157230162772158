import { iBaseDynamicFormField } from './base-dynamic-field';
import { DynamicField } from './dynamic-field';

export enum GroupLayout {
  Toggle = 'toggle',
}

export interface iGroupFormField extends iBaseDynamicFormField {
  /**
   * Determines whether the group is nested or not.
   *
   * Nested groups will have its data nested.
   */
  nested: boolean;

  /**
   * Determines the layout of the group.
   *
   * Setting it to 'toggle' will add a control that shows or hides the child fields.
   *
   * Currently only supports:
   *  - toggle
   */
  layout?: GroupLayout;

  /**
   * Fields of the group.
   */
  fields: DynamicField[];

  /**
   * Determines whether the group should be used as a repeatable group.
   * Should be a reference to an array.
   */
  repeat?: string;
}
