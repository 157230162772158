import { Injectable } from '@angular/core';

import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { ConfigService, NavigationService } from '@semmie/services';

import { Utils } from '@onyxx/utility/general';
import { UserStoreFacade } from '@semmie/store/user';
import { AppRouteNames } from '@onyxx/model/main';
import { UserTaskStoreFacade } from '@onyxx/store/user-task';
import { TaskPriority } from '@onyxx/model/task';

@Injectable()
export class CriticalTaskGuard {
  constructor(
    private userFacade: UserStoreFacade,
    private navService: NavigationService,
    private configService: ConfigService,
    private userTaskStoreFacade: UserTaskStoreFacade,
  ) {}

  canActivate() {
    return combineLatest([
      this.userFacade.taskCountCritical$.pipe(filter(Utils.isNotNil)),
      this.configService.config$.pipe(map((c) => c.config)),
    ]).pipe(
      take(1),
      switchMap(([taskCountCritical, config]) => {
        if (taskCountCritical > 0 && config.features.criticalTasks.guard) {
          return this.userTaskStoreFacade.urgentTasks$.pipe(
            map((tasks) => {
              const criticalTasksThatAreUrgent = tasks?.filter((t) => t.priority === TaskPriority.CRITICAL).length ?? 0;
              if (criticalTasksThatAreUrgent === 0) return true;

              return this.navService.createUrlTree([AppRouteNames.UrgentTasks]);
            }),
          );
        }
        return of(true);
      }),
    );
  }
}
