import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormControlStatus, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { FormInputComponent } from '@semmie/components/containers/form-input/form-input.component';
import { iBackendFieldsErrorResponse } from '@semmie/schemas/bi/error/backend-field-error-response.schema';
import { DynamicField, iGroupFormField } from '@semmie/schemas/components/dynamic-form';
import { iDynamicForm } from '@semmie/schemas/components/dynamic-form/dynamic-form';
import { DynamicFormFieldEvent } from '@semmie/schemas/components/dynamic-form/dynamic-form-event';
import { DynamicFieldType } from '@semmie/schemas/components/dynamic-form/dynamic-form-types';
import { FormService } from '@semmie/services/form/form.service';
import { JmespathService } from '@semmie/services/jmespath/jmespath.service';
import { Utils } from '@onyxx/utility/general';
import { InterpolateService } from '@semmie/services/interpolate/interpolate.service';

import { BaseComponent } from '@semmie/components/_abstract';
import { ValuePathType } from '@semmie/schemas/components/dynamic-form/form-fields';

@Component({
  selector: 'semmie-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Holds a reference to all fields `(FormInputComponent)` rendered by the `FormComponent`, except for the `FormGroupComponent`.
   *
   * Useful for querying fields and calling its logic.
   */
  @ViewChildren('semmieField') fields: QueryList<FormInputComponent>;

  /**
   * Unused.
   * `data$` could be used to asynchronously set the data of the form (fields).
   * It was used a long time ago with user-profile.
   *
   * !todo: delete
   */
  @Input() data$: Observable<any>;

  /**
   * Represents data to be used in a synchronous manner, e.g. all data is available _before_ the fields are rendered.
   */
  @Input() data: any;

  /**
   * Represents the `DynamicForm` to be rendered.
   */
  @Input() dynamicForm: iDynamicForm;

  /**
   * Stores the backend error responses, which contains a general error code and a list of details which are mapped to a field by key.
   */
  @Input() errors: iBackendFieldsErrorResponse;
  @Input() enabledFormSubmit = false;
  @Input() enableSideSpacing = false;

  /**
   * Emits sanitized values on data change.
   *
   * Sanitized means without any fields that are meant for visual purposes or as data sources.
   */
  @Output() onFormDataChange: EventEmitter<any> = new EventEmitter();

  @Output() onFormStatusChange: EventEmitter<FormControlStatus> = new EventEmitter();

  formEvents$ = new BehaviorSubject<DynamicFormFieldEvent | null>(null);

  /**
   * Unused.
   * Never used. The idea was to set the form data here, however formValue was used instead.
   *
   * !todo: delete
   */
  formData: Observable<any>;

  /**
   * Contains the FormGroup status. Unused.
   */
  formStatus: Observable<any>;

  /**
   * The FormGroup containing all controls
   */
  formGroup = this.formBuilder.group({});

  /**
   * Contains the current sanitized form value.
   *
   * Sanitized means without any fields that are meant for visual purposes or as data sources.
   *
   */
  formValue: any;

  /**
   * Represents the initialization state of the Form.
   *
   * The Form is ready if all controls are added to the FormGroup.
   */
  initialized = false;

  /**
   * Unused.
   * It's a way to peek whether the form is validated or not. Although it would be preferred to expose the ability to do so like this, we're currently literally peeking at the state through the formGroup.
   *
   * !todo: delete
   */
  validated = false;

  $meta: Record<string, any> = {};

  private destroyed: Subject<boolean> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formService: FormService,
    private jmesPathService: JmespathService,
    private interpolateService: InterpolateService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dynamicForm && !changes.dynamicForm.firstChange) this.initializeForm();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  get valid(): boolean {
    return this.formGroup.valid;
  }

  get invalid(): boolean {
    return this.formGroup.invalid;
  }

  get pristine(): boolean {
    return this.formGroup.pristine;
  }

  /**
   * Returns the error message of the field.
   *
   * @param fieldName name of the field
   * @returns A message as a string or null
   */
  getErrorMessage(fieldName: any): string | null {
    if (!this.errors) {
      return null;
    }

    return this.formService.handleBackendErrors(fieldName, this.errors);
  }

  /**
   * Set the error message of the field.
   *
   * ! Currently not used properly. Either rename this to `clearErrorMessages` or pass the proper value(s) and make use of it in the code below.
   *
   * @param fieldName name of the field
   */
  setErrorMessage(fieldName: string) {
    if (!this.errors) return;
    this.errors.details[`${this.dynamicForm.reference || ''}.${fieldName}`] = [];
  }

  /**
   * Validate the Form and return an object that represents its validity state and status.
   *
   * @returns `{ valid: boolean, invalid: boolean, status: string }`
   */
  validate(): { valid: boolean; invalid: boolean; status: string } {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    this.cdr.markForCheck();

    this.validated = true;

    return {
      valid: this.formGroup.valid,
      invalid: this.formGroup.invalid,
      status: this.formGroup.status,
    };
  }

  /**
   * Update the Form with the given values.
   *
   * Currently used to update the values from an `ExternalDataComponent`.
   *
   * @param data the data to update the controls with
   * @param emitEvent tells the group to emit an event after updating
   * @param firstChange marks the group as pristine if set
   * @param meta additional $meta that the form should store
   */
  updateFormValue(data: any, emitEvent?: boolean, firstChange?: boolean, meta: Record<string, any> = {}): void {
    Object.keys(data).forEach((key) => {
      if (this.formGroup.controls[key]) {
        this.formGroup.patchValue({ [key]: data[key] }, { emitEvent });
      } else {
        this.formGroup.setValue({ [key]: data[key] }, { emitEvent });
      }
    });

    this.dynamicForm?.fields?.forEach((field) => {
      if (field?.type === 'group' && field.fields?.length) {
        field.fields?.forEach((groupField) => {
          if (Utils.isNil(field.valuePathType) || field.valuePathType === ValuePathType.CHANGES || firstChange) {
            this.interpolateValuePath(groupField, field);
          }
        });
      }
      if (Utils.isNil(field.valuePathType) || field.valuePathType === ValuePathType.CHANGES || firstChange) {
        this.interpolateValuePath(field);
      }
    });

    this.$meta = { ...this.$meta, ...meta };

    if (firstChange) {
      this.formGroup.markAsPristine();
    }
  }

  /**
   * Returns an array of fields and their respective streams.
   *
   * @param fieldType Any streamable field type (ExternalData and Upload)
   * @param fieldName Optional. Filter directly by fieldName
   * @returns An array of fields with their streams
   */
  getDataStreams(fieldType: DynamicFieldType.ExternalData | DynamicFieldType.Upload, fieldName?: string) {
    return this.formGroup.valueChanges.pipe(
      startWith({}),
      debounceTime(1000),
      distinctUntilChanged(Utils.isEqual),
      map(() => {
        const streams = this.fields
          .toArray()
          .filter((f) => f.field.type === fieldType)
          .filter((f) => (fieldName ? f.field.name === fieldName : true))
          .map((f) => {
            return {
              field: f.field,
              stream: f.externalDataComponent?.requestStream$,
            };
          });

        return streams;
      }),
      takeUntil(this.destroyed),
    );
  }

  /**
   * Returns the field by name.
   *
   * @param fieldName name of the field
   * @returns a reference to the requested field as a FormInputComponent
   */
  getFieldByName(fieldName: string) {
    return this.fields.toArray().find((f) => f.field.name === fieldName);
  }

  /**
   * Reinitialize the external data fields.
   *
   * Currently used to reinitialize the organisation fields.
   */
  reinitExternalDataFields() {
    const fields = this.fields.filter((f) => f.field.type === DynamicFieldType.ExternalData);

    if (fields?.length) {
      fields.forEach((f) => f.externalDataComponent?.reinit());
    }
  }

  /**
   * Returns the current form value and data.
   *
   * Mostly used for interpolating.
   *
   * @returns an object that contains the current form value and data.
   */
  getFormValueAndData() {
    return { ...this.data, ...this.formGroup.getRawValue(), $meta: this.$meta };
  }

  /**
   * Initialize the data of the FormGroup
   *
   * !todo: refactor as this is inaptly named and not used properly
   */
  private initializeData$(): void {
    if (this.data$) {
      this.data$.pipe(take(1)).subscribe(
        (data) => {
          this.formGroup.patchValue(data);
        },
        null,
        () => {
          this.initialized = true;
          this.cdr.markForCheck();
        },
      );
    } else {
      this.initialized = true;
      this.cdr.markForCheck();
    }
  }

  /**
   * Initializes the Form
   */
  private initializeForm(): void {
    this.initialized = false;
    this.validated = false;

    this.formGroup = this.formBuilder.group({});

    if (this.dynamicForm?.fields?.length) {
      for (const field of this.dynamicForm.fields) {
        const isGroup = field.type === DynamicFieldType.Group;

        // TODO(forms): sometimes you don't want a form control when it's a form group add this usecase later.
        if (isGroup) {
          this.addGroupControls(field);
        } else {
          this.addControl(field);
        }
      }
    }

    this.formGroup.markAsPristine();

    if (!this.formGroup) return;

    this.formValue = this.formGroup.getRawValue() ?? {};

    this.formGroup.statusChanges
      .pipe(debounceTime(300), takeUntil(this.destroyed))
      .subscribe((status) => this.onFormStatusChange.emit(status));

    this.formGroup.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.updateFormValidations(this?.dynamicForm?.fields);

      const sanitized = this.sanitizeFormValues(this.formGroup.getRawValue());
      this.formValue = { [this.dynamicForm.reference]: sanitized };
      this.onFormDataChange.emit(this.formValue);
    });

    this.formStatus = this.formGroup.statusChanges.pipe(
      tap(() => {
        this.validated = false;
      }),
      startWith(this.formGroup.status),
    );

    this.initializeData$();
  }

  /**
   * Update the form validation(s).
   *
   * This is recalculated on any form valueChange.
   */
  private updateFormValidations(fields?: Array<DynamicField>, group?: DynamicField): void {
    if (!this.initialized || !fields) return;

    for (const field of fields) {
      if (field.type === DynamicFieldType.Group) {
        this.updateFormValidations((field as DynamicField)?.fields, field);
      }

      const mainControl = this.formService.getFormControl(field, this.formGroup, group);
      // if the group is a repeater field, retrieve the control in the form array
      const formControl = !group?.repeat ? mainControl : (mainControl?.get('0')?.get(field.name) as UntypedFormControl | null);

      if (!formControl) return;

      if (!this.formService.meetsConditions(field.conditions, { ...this.getFormValueAndData(), $: field.$ ?? {} })) {
        formControl.clearValidators();
      } else {
        formControl.setValidators(this.formService.parseValidationRules(field?.validations, this.getFormValueAndData()));
      }

      formControl.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    }
  }

  /**
   * Add a control to the FormGroup.
   *
   * Each control will have its value, disabled-state and validators evaluated and interpolated upon adding.
   *
   * @param field the DynamicField to add as a control
   * @param groupField the group of the DynamicField (if applicable)
   */
  private addControl(field: DynamicField, groupField?: DynamicField): void {
    const actualFormGroup = this.formService.getFormGroup(groupField ?? field, this.formGroup);

    actualFormGroup.addControl(
      field.name,
      new UntypedFormControl(
        {
          value: this.getFieldValue(field, groupField?.name),
          disabled: typeof field.disabled === 'object' ? this.interpolateDynamicExpression(field.disabled) : field.disabled,
        },
        {
          validators: this.formService.meetsConditions(field.conditions, {
            ...this.getFormValueAndData(),
            $: field.$ ?? {},
          })
            ? this.formService.parseValidationRules(field?.validations, this.getFormValueAndData())
            : [],
          updateOn: 'blur',
        },
      ),
    );
  }

  /**
   * Add controls for a group and its fields.
   *
   * Currently, this supports the following group use-cases:
   *  1. groups as a manner of grouping data.
   *  2. groups as a manner of visual grouping.
   *  3. groups that contain a number of fields to be repeated _n_ times
   *
   * !todo: refactor this so that the logic flow is easier to grasp. E.g.
   * either extract each code path to its own function OR refactor each group 'type' to its own. Should also be able to handle groups as both FormArrays and FormGroups.
   *
   * @param field field reference to a group
   */
  private addGroupControls(field: DynamicField) {
    /**
     * If the group field is a repeat group
     */
    if ((field as iGroupFormField).repeat) {
      this.formGroup.addControl(field.name, new UntypedFormArray([], this.addValidators(field)));
    } else if (!(field as iGroupFormField).nested) {
      /**
       * If the group field is just a visual representation of a group
       *
       * !todo: can be removed once input and output expression are supported
       */
      this.addControl(field);
    } else {
      /**
       * If the group field is nested and groups the data
       */
      this.formGroup.addControl(field.name, new UntypedFormGroup({}, this.addValidators(field)));
      this.addControl(field, field);
    }

    const isRepeatable = !!field.repeat;

    if (isRepeatable) {
      let groupFieldIndex = 0;

      /**
       * repeatReference is a reference to an array, it the reference is not an array, no logic will be executed.
       */
      const repeatReference = this.jmesPathService.interpolate({ ...this.formGroup.getRawValue(), ...this.data }, field.repeat);

      if (Array.isArray(repeatReference)) {
        for (const elem of repeatReference) {
          if ((field as iGroupFormField)?.fields?.length) {
            const repeatControls = new UntypedFormGroup({});

            for (const groupField of (field as iGroupFormField).fields) {
              // continue the loop if the groupField doesn't meet the conditions
              if (!this.formService.meetsConditions(groupField.conditions, { ...this.getFormValueAndData(), $: elem })) continue;
              if (!groupField.$) groupField.$ = [];

              /*
                Increment the index for visual purposes and ensure it starts at 1
              */
              groupField.$.push({ ...elem, $index: groupFieldIndex + 1 });

              repeatControls.addControl(
                groupField.name,
                new UntypedFormControl(
                  {
                    value: this.getFieldValue(groupField as DynamicField, undefined, groupFieldIndex),
                    disabled:
                      typeof groupField.disabled === 'object'
                        ? this.interpolateDynamicExpression(groupField.disabled)
                        : groupField.disabled,
                  },
                  this.addValidators(groupField as iGroupFormField, { $: elem }),
                ),
              );
            }

            /**
             * if we have controls, add them to the group
             */
            if (Object.keys(repeatControls?.controls)?.length) {
              const group = this.formService.getFormArray(field, this.formGroup);
              group.push(repeatControls);

              const actualFormGroup = this.formService.getFormGroup(field, this.formGroup);
              actualFormGroup.addControl(field.name, group);

              groupFieldIndex++;
            }
          }
        }
      }
    } else {
      if ((field as iGroupFormField)?.fields?.length) {
        for (const groupField of (field as iGroupFormField).fields) {
          this.addControl(groupField as DynamicField, field);
        }
      }
    }
  }

  /**
   * Calculates the validation rules of the field.
   *
   * @param field reference to the DynamicField or iGroupFormField
   * @param data data to use for interpolation
   * @returns
   */
  private addValidators(
    field: DynamicField | iGroupFormField,
    data?: Record<string, any>,
  ): {
    validators: ValidatorFn[];
    updateOn: 'change' | 'blur' | 'submit';
  } {
    return {
      validators: this.formService.meetsConditions(field.conditions, Object.assign({}, this.formGroup.value, data ?? {}))
        ? this.formService.parseValidationRules(field?.validations, Object.assign({}, this.formGroup.value, data ?? {}))
        : [],
      updateOn: 'blur',
    };
  }

  /**
   * Sanitize the form values by removing data that isn't necessary or expected by the backend.
   *
   * Removes:
   * - external_data values
   * - static fields
   * - labels
   * - values of a nested group (and itself) if it is not truthy OR the group if it is a nested group while retaining its field values.
   * - any value that is undefined
   *
   * !todo: use Utils here
   *
   * @param values Form values
   * @returns Sanitized form values
   */
  private sanitizeFormValues(values: any): any {
    const valuesCpy = { ...values };

    for (const key in valuesCpy) {
      const field = this.dynamicForm.fields.find((f) => f.name === key);

      if (field) {
        if (this.formService.isStaticField(field)) {
          delete valuesCpy[key];
        }

        if (field.type === DynamicFieldType.Group && (field as iGroupFormField).nested) {
          // if the group value is falsy
          if (!valuesCpy[key][key]) {
            Object.keys(valuesCpy[key]).forEach((k) => {
              delete valuesCpy[key][k];
            });

            delete valuesCpy[key];
          } else {
            delete valuesCpy[key][key];
          }
        }
      }

      if (typeof values[key] === 'undefined') {
        delete valuesCpy[key];
      }
    }

    return valuesCpy;
  }

  /**
   * Determine the value of the field and return it.
   *
   * The _value_ is determined as follows:
   * * If we are able to interpolate the value by in any way and it is _not_ `null` or `undefined`, return the value
   *
   * * If the value _is_ `null` or `undefined` _and_ we have specified a _value_ in the form definitions, return the field.value
   *
   * * If the value _is_ `null` or `undefined` _and_ we have specified a _valuePath_ in the form definitions, return _null_. This is in order to dynamically set the value at a later stage
   *
   * * If none of the above is returned, return the default value of the `field.type`. (See `getDefaultFieldValueByType()`).
   *
   */
  private getFieldValue(field: DynamicField, group?: string, fieldIndex?: number) {
    let value: any = null;

    if (this.data) {
      if (field.valuePath) {
        const interpolated = this.jmesPathService.interpolate(
          Object.assign(this.getFormValueAndData(), { $: field.$ ? field.$[fieldIndex ?? 0] : {} }),
          field.valuePath,
        );

        value = interpolated;
      }

      if (group && (field as iGroupFormField).nested && !Utils.isNil(this.data[group])) {
        value = this.data[group][field.name];
      } else if (group && !(field as iGroupFormField).nested && !Utils.isNil(this.data)) {
        value = this.data[field.name];
      } else if (!Utils.isNil(this.data[this.dynamicForm.reference]) && Utils.isObject(this.data[this.dynamicForm.reference])) {
        value = this.data[this.dynamicForm.reference][field.name];
      } else if (!Utils.isNil(this.data[field.name]) && (field as iGroupFormField)?.property) {
        value = this.data[field.name][field.property];
      } else if (!Utils.isNil(this.data[field.name])) {
        value = this.data[field.name];
      }
    }

    if (!Utils.isNil(value)) return value;
    else if (!Utils.isNil(field.value)) return field.value;
    else if (!Utils.isNil(field.valuePath)) return null;
    else return this.formService.getDefaultFieldValueByType(this.formService.getFieldType(field));
  }

  /**
   * Interpolate the valuePath of the field and patches the FormGroup with the result.
   *
   * Currently only called when an ExternalData field triggers.
   *
   * @param field the DynamicField to use for interpolation
   * @param group  groupField (if applicable) for grouping the data
   */
  private interpolateValuePath(field: DynamicField, group?: DynamicField): void {
    if (field.valuePath) {
      const renderedString = this.interpolateService.renderString(field.valuePath, this.getFormValueAndData());
      const interpolated = this.jmesPathService.interpolate(this.getFormValueAndData(), renderedString);

      const groupField = field.type === DynamicFieldType.Group && field.nested ? field : (group ?? null);

      let combinedData = {};

      if (groupField) {
        if (groupField.repeat) {
          combinedData = {
            [groupField.name]: [
              {
                [field.name]: interpolated,
              },
            ],
          };
        } else {
          combinedData = {
            [groupField.name]: {
              [field.name]: interpolated,
            },
          };
        }
      } else {
        combinedData = {
          [field.name]: interpolated,
        };
      }

      const fieldValue = this.jmesPathService.interpolate(
        this.getFormValueAndData(),
        `${groupField ? groupField.name + '.' : ''}${field.name}`,
      );

      if (
        field.disabled ||
        (field.valuePathType !== ValuePathType.DEFAULT_VALUE && Utils.isNotNil(interpolated)) ||
        (field.valuePathType === ValuePathType.DEFAULT_VALUE && Utils.isNil(fieldValue) && Utils.isNotNil(interpolated))
      ) {
        this.formGroup.patchValue(combinedData, { emitEvent: true });
        this.formGroup.get(field.name)?.markAsUntouched();
      }
    }
  }

  /**
   * Interpolate a jmespath expression.
   * @param property jmespath expression
   * @returns the result of the expression
   */
  private interpolateDynamicExpression(property: any): any {
    if (!property?.path) return '';
    return this.jmesPathService.interpolate(this.formGroup.value, property.path);
  }
}
