<ion-refresher *ngIf="pullToRefresh" slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200" (ionRefresh)="refreshList($any($event))">
  <ion-refresher-content></ion-refresher-content>
</ion-refresher>

<ng-container *ngIf="(shouldShowLoader$ | async) === false; else loadingTpl">
  <ng-container *ngIf="(isEmptyList$ | async) === false || hideEmptyState; else emptyListTpl">
    <ng-content></ng-content>

    <div class="list-container" [class.columns]="useColumns" [class.gaps]="itemGap" [ngStyle]="{ gap: itemGap, 'pb-6': !canLoadMore }">
      <ng-container *ngIf="beforeItemsTemplateRef" [ngTemplateOutlet]="beforeItemsTemplateRef"></ng-container>

      <div
        *ngFor="let item of bufferedItems$$ | async; let index = index; let first = first; let last = last; trackBy: trackByFn"
        class="semmie-list-item {{ !item ? 'placeholder' : '' }}"
      >
        <ng-container
          *ngIf="templateRef && item"
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{ $implicit: item, position: index, first: first, last: last }"
        >
        </ng-container>
      </div>

      <ng-container *ngIf="placeholderItems.length">
        <div
          class="semmie-list-item placeholder"
          *ngFor="let item of placeholderItems; let first = first; let last = last; trackBy: trackByFn"
        >
          <ng-container
            *ngIf="placeholderTemplateRef"
            [ngTemplateOutlet]="placeholderTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: item, first: first, last: last }"
          >
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="afterItemsTemplateRef" [ngTemplateOutlet]="afterItemsTemplateRef"></ng-container>
    </div>
    <div *ngIf="canLoadMore" class="flex justify-center py-6 {{ loadModeButtonConfig.padding }}">
      <semmie-button
        [theme]="loadModeButtonConfig.theme"
        [fullWidth]="loadModeButtonConfig.fullWidth"
        [size]="loadModeButtonConfig.size"
        [ngClass]="{ 'w-full': loadModeButtonConfig.fullWidth }"
        (onClick)="loadMore()"
        i18n="trigger the infinite scroll|A button to load more items@@load-more"
        >Load More</semmie-button
      >
    </div>
  </ng-container>
</ng-container>

<ng-template let-item #emptyListTpl>
  <ng-container *ngIf="emptyTemplateRef" [ngTemplateOutlet]="emptyTemplateRef" [ngTemplateOutletContext]="{ $implicit: item }">
  </ng-container>
</ng-template>

<ng-template #loadingTpl>
  <div>
    <ng-content select="[slot=loading]"></ng-content>
    <!--
      The absolute loading ripple is problematic when using the scrollable list in
      a modal. The slot loading slot should be used to hide the fixed spinner with an
      inline spinner
    -->
    <div class="hidden only:block">
      <semmie-loading-ripple></semmie-loading-ripple>
    </div>
  </div>
</ng-template>
