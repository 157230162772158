import { Injectable } from '@angular/core';
import { PayoutCalculation } from '@onyxx/model/payout';
import { PayoutProvider } from '@onyxx/provider/payout';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GoalService {
  constructor(private payoutProvider: PayoutProvider) {}

  /**
   * Calculate the annuity payout
   * Used in `summary-plan-annuity-payout.form.ts`
   */
  calculatePayout({ amount_transfer, frequency, period }: any): Observable<PayoutCalculation> {
    return this.payoutProvider.calculatePayout({ amount_transfer, frequency, period }).pipe(take(1));
  }
}
