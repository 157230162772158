import { ChangeDetectionStrategy, Component, Input, OnChanges, computed, signal } from '@angular/core';
import { RiskProfileAssetSpread } from '@onyxx/model/risk-profile';
import { BaseComponent } from '@semmie/components/_abstract';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { PercentageModule } from '@semmie/pipes/percentage/percentage-pipe.module';
import { SharedModule } from '@semmie/shared/shared.module';

const stocksColor = 'var(--color-asset-allocation-stocks)';
const bondsColor = 'var(--color-asset-allocation-bonds)';
const privateMarketColor = 'var(--color-asset-allocation-private-market)';
const cashColor = 'var(--color-asset-allocation-cash)';

const donutChartGap = 3;
const gapColor = 'var(--color-asset-allocation-fill)';

@Component({
  standalone: true,
  imports: [SharedModule, LabelModule, PercentageModule],
  selector: 'semmie-portfolio-assets-allocation',
  templateUrl: 'portfolio-assets-allocation.component.html',
  styleUrls: ['portfolio-assets-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: refactor inputs to use signals when available in v17
export class PortfolioAssetsAllocationComponent extends BaseComponent implements OnChanges {
  @Input() assetSpread?: RiskProfileAssetSpread;
  @Input() contrast = false;

  readonly bondsPct$$ = signal(0);
  readonly stocksPct$$ = signal(0);
  readonly privateMarketPct$$ = signal(0);
  readonly cashPct$$ = signal(0);

  readonly assetIndicatorBackground$$ = computed(() =>
    generateConicGradient(donutChartGap, gapColor, [
      { fraction: this.stocksPct$$(), color: stocksColor },
      { fraction: this.bondsPct$$(), color: bondsColor },
      { fraction: this.privateMarketPct$$(), color: privateMarketColor },
      { fraction: this.cashPct$$(), color: cashColor },
    ]),
  );

  // TODO: remove on changes with the input signals in v17
  ngOnChanges(): void {
    const bonds = this.assetSpread?.bonds ?? 0;
    const stocks = this.assetSpread?.stock ?? 0;
    const privateMarket = this.assetSpread?.private_market ?? 0;
    const cash = this.assetSpread?.cash ?? 0;

    // TODO: is this really needed? This actually should be always 100, but we need to check if BE can return anything else. Kept it for flexibility
    const totalAssets = bonds + stocks + privateMarket + cash;

    this.bondsPct$$.set(bonds / totalAssets);
    this.stocksPct$$.set(stocks / totalAssets);
    this.privateMarketPct$$.set(privateMarket / totalAssets);
    this.cashPct$$.set(cash / totalAssets);
  }
}

/**
 * Generates a conic gradient with a specified gap and gap color between the segments defined by an array of objects.
 * @param gap The size of the gap in degrees between each segment of the conic gradient.
 * @param gapColor The color of the gap between each segment of the conic gradient.
 * @param segments An array of objects representing each segment of the conic gradient. Each object has a 'fraction' and a 'color'.
 * @returns A string representing the CSS background value of the generated conic gradient.
 */
function generateConicGradient(gap: number, gapColor: string, segments: { fraction: number; color: string }[]): string {
  let cssString = 'conic-gradient(';
  let degreeStartCounter = 0;

  // Add an initial gap if there are segments
  if (segments.length > 0) {
    cssString += `${gapColor} ${degreeStartCounter}deg ${degreeStartCounter + gap}deg, `;
    degreeStartCounter += gap;
  }

  segments.forEach(({ fraction, color }, index) => {
    const startDegree = degreeStartCounter;
    const segmentDegree = 360 * fraction;

    // Calculate the end degree based on the segment fraction and gap
    const endDegree = degreeStartCounter + segmentDegree - gap;

    // Add the actual segment color
    cssString += `${color} ${startDegree}deg ${endDegree}deg`;

    // Add a gap after this segment if it's not the last one, or if it is the last one, circle back to the beginning
    if (index < segments.length - 1 || endDegree < 360) {
      cssString += `, ${gapColor} ${endDegree}deg ${endDegree + gap}deg, `;
    }

    degreeStartCounter += segmentDegree;
  });

  // Remove trailing comma and space, if present
  if (cssString.endsWith(', ')) {
    cssString = cssString.slice(0, -2);
  }

  cssString += ')';
  return cssString;
}
