import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';

import { BehaviorSubject, combineLatest, EMPTY } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { Share } from '@capacitor/share';

import { BaseComponent } from '@semmie/components/_abstract';
import { Booster } from '@semmie/models/bi/booster/booster.model';
import { Icon } from '@semmie/schemas';
import { BoosterType } from '@semmie/schemas/bi/booster';
import { RewardKind } from '@semmie/schemas/bi/reward';
import { ModalSize } from '@semmie/schemas/components/modal';
import { AppRateService, BoosterService, ModalService } from '@semmie/services';
import { ClipboardService } from '@semmie/services/clipboard/clipboard.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { IllustrationBooster } from '@semmie/shared/globals';
import { ToastService } from '@semmie/services/toast/toast.service';
import { PaymentProviderEnum } from '@semmie/schemas/bi/payment';
import { SharedModule } from '@semmie/shared/shared.module';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { ButtonModule } from '@semmie/components/presentational/core/button/button.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { InfoModalModule } from '@semmie/components/containers/modals/info-modal/info-modal.module';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { CurrencyModule } from '@semmie/pipes/currency/currency-pipe.module';
import { ContentCardComponent } from '@semmie/components/containers/content-card/content-card.component';
import { UserStoreFacade } from '@semmie/store/user';
import { ReferralStoreFacade, ReferralStoreModule } from '@semmie/store/referral';
import { filterNil } from '@onyxx/utility/observables';
import { Account, AccountKindWithAutoIncasso, AccountState } from '@onyxx/model/account';
import { AccountProvider } from '@onyxx/provider/account';
import { AppRouteNames } from '@onyxx/model/main';
import { ContentToStringComponent } from '@onyxx/ui/content-to-string';
import { environment } from 'environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    ButtonModule,
    CardModule,
    IconComponent,
    ImageModule,
    InfoModalModule,
    LabelModule,
    CurrencyModule,
    ContentCardComponent,
    ReferralStoreModule,
    ContentToStringComponent,
  ],
  selector: 'semmie-booster-card',
  templateUrl: 'booster-card.component.html',
  styleUrls: ['./booster-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoosterCardComponent extends BaseComponent {
  @Input() account?: Partial<Account>;
  @Input() layout: 'carousel' | 'item' = 'carousel';
  @Input() minimumPayment: number;

  booster$$ = new BehaviorSubject<Booster | null>(null);
  booster$ = this.booster$$.asObservable().pipe(filterNil());

  image$ = this.booster$.pipe(map((booster) => this.platformService.resolveAssetPath(this.getBoosterImage(booster.type))));
  buttonDisabled$ = this.booster$.pipe(map((booster) => this.getButtonDisabled(booster)));

  readonly BoosterType = BoosterType;
  readonly Icon = Icon;
  readonly RewardKind = RewardKind;
  referral$ = this.referralStoreFacade.userReferral$;
  readonly referralLink$$ = toSignal(
    this.referral$.pipe(map((referral) => `${environment.universalLink}/${AppRouteNames.Invitations}/${referral.referral_code}`)),
  );
  readonly rewardText$$ = signal<string | null>(null);
  readonly rewardTextDescription$$ = signal<string | null>(null);

  @Input()
  set booster(value: Booster) {
    this.booster$$.next(value);
  }

  constructor(
    private boosterService: BoosterService,
    private referralStoreFacade: ReferralStoreFacade,
    private platformService: PlatformService,
    private userFacade: UserStoreFacade,
    private accountProvider: AccountProvider,
    private modalService: ModalService,
    private clipboardService: ClipboardService,
    private toastService: ToastService,
    private appRateService: AppRateService,
  ) {
    super();
  }

  primaryCta(type: BoosterType) {
    switch (type) {
      case BoosterType.REFERRAL:
        this.shareOrCopy();
        break;
      case BoosterType.REVIEW:
        this.appRateService.openKiyohReviewPage();
        break;
      default:
        this.handleBoosterCta();
        break;
    }
  }

  private getBoosterImage(type: BoosterType): string {
    switch (type) {
      case BoosterType.AUTO_INCASSO:
        return IllustrationBooster.AUTO_INCASSO;
      case BoosterType.CAMPAIGN_TRANSAVIA:
        return IllustrationBooster.CAMPAIGN_TRANSAVIA;
      case BoosterType.CAMPAIGN_TRYOUT:
        return IllustrationBooster.CAMPAIGN_TRYOUT;
      case BoosterType.CAMPAIGN_CADEAU:
        return IllustrationBooster.CAMPAIGN_CADEAU;
      case BoosterType.REFERRAL:
        return IllustrationBooster.REFERRAL;
      case BoosterType.REFERRED:
        return IllustrationBooster.REFERRED;
      case BoosterType.REVIEW:
        return IllustrationBooster.REVIEW;
      default:
        return IllustrationBooster.CAMPAIGN_GIFT;
    }
  }

  private handleBoosterCta() {
    this.accountProvider
      .list({ state_from: AccountState.INVESTING, state_till: AccountState.ARCHIVED, kind: AccountKindWithAutoIncasso })
      .pipe(
        take(1),
        switchMap((accounts) => {
          const activeAccounts = accounts.data;

          if (activeAccounts.length === 0) {
            return this.openNoActiveAccountsToast();
          } else if (activeAccounts.length === 1) {
            return this.navigateToAutoIncassoPayment(activeAccounts[0].id);
          } else {
            return this.handleMultipleAccountIncassoBoosterCta(activeAccounts);
          }
        }),
      )
      .subscribe();
  }

  private openNoActiveAccountsToast() {
    return this.toastService.show({
      header: $localize`:@@boosters.no-active-accounts-toast.title:You don't have any active accounts yet.`,
      message: $localize`:@@boosters.no-active-accounts-toast.message:Create your first account or go to an account to see the status.`,
    });
  }

  private handleMultipleAccountIncassoBoosterCta(accounts: Account[]) {
    this.modalService.openSelectionModal(
      { title: $localize`:@@boosters.select-account:Which account?` },
      ModalSize.Auto,
      accounts.map(({ title, id }) => ({ label: title ?? '', id })),
    );

    return this.modalService.onWillClose$.pipe(
      take(1),
      switchMap((result?: { id: string; label: string }) => {
        if (result?.id) {
          return this.navigateToAutoIncassoPayment(result.id);
        }
        return EMPTY;
      }),
    );
  }

  private navigateToAutoIncassoPayment(accountId: string) {
    this.modalService.openPaymentDialog(PaymentProviderEnum.directDebit, { accountId });

    return this.modalService.onWillClose$.pipe(
      switchMap(() => {
        this.userFacade.load();

        return this.userFacade.latestUser$.pipe(take(1));
      }),
      tap(() => this.boosterService.boostersChanged$$.next(undefined)),
      take(1),
    );
  }

  private shareOrCopy() {
    combineLatest([this.referral$, this.userFacade.user$.pipe(filterNil())])
      .pipe(
        take(1),
        tap(async ([{ referral_url: url }, { name_casual: nameCasual }]) => {
          if (this.platformService.isMobile && (await Share.canShare()).value) {
            await Share.share({
              url,
              dialogTitle: $localize`:@@boosters.referral-share.title:${nameCasual} is sending you an invitation for Semmie`,
              title: $localize`:@@boosters.referral-share.title:${nameCasual} is sending you an invitation for Semmie`,
              text: $localize`:@@boosters.referral-share.text:Through Semmie, I invest in sustainable stocks and bonds. Would you like to try Semmie as well? You can try Semmie for 1 month without a management fee via this link:\n`,
            });
          } else {
            await this.clipboardService.copy(url, true, $localize`:@@common.labels.link:Link`);
          }
        }),
      )
      .subscribe();
  }

  private getButtonDisabled(booster: Booster): boolean {
    return [BoosterType.CAMPAIGN_TRYOUT, BoosterType.CAMPAIGN_CADEAU].includes(booster.type);
  }
}
